import config from '../../../config'
import store from '../../store'
import moment from 'moment'
import jsPDF from 'jspdf'
import QRCode from 'qrcode'
import { PDFDocument, StandardFonts } from 'pdf-lib'
import { NumerosALetras } from 'numero-a-letras'
import { format_money, order_list_by, ArrayBufferToBase64, divideArray, parseDate, convertDecimals, getDims, str, nvl } from '../utils'

async function get_pdf (nombre) {
  // devuelve los bytes del pdf que esta almacenado en la api
  const pdfBytes = await fetch(`${config.BASE_URL}/pdf?nombre=${nombre}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
      'Authorization': store.state.long_token
    },
  }).then(res => res.arrayBuffer())

  return pdfBytes
}

export async function get_file (path, tipo) {
  // devuelve los bytes del pdf que esta almacenado en la api
  const fileBytes = await fetch(`${config.BASE_URL}/file?path=${path}`, {
    method: 'GET',
    headers: {
      'Content-Type': tipo,
      'Authorization': store.state.long_token
    },
  }).then(res => res.arrayBuffer())

  return fileBytes
}

// si es muy largo un nombre lo recorta en la 'longMax' y devuelve 2 partes
function salto_linea (nombre, longMax) {
  if (nombre.length > longMax) {
    let indices = []
    // carga un array con los indices donde hay espacios
    for(let i = 0; i < nombre.length; i++) {
      if (nombre[i] === ' ') indices.push(i)
    }

    /*
    // calcula cuál es el indice que está más a la mitad del array
    const mitad =  indices[Math.trunc(indices.length / 2)]
    */

    // calcula cual es el indice que mas se aproxima a la longitud maxima permitida
    let sub = indices.filter(i => i < longMax)
    const mitad = sub.length == 0 ? indices[0] : sub[sub.length - 1]

    return {
      parte1: nombre.substring(0, mitad),
      parte2: nombre.substring(mitad + 1, nombre.length)
    }
    
  } else {
    return {
      parte1: nombre,
      parte2: ''
    }
  }
}

function split_varchar (varchar, longitud_maxima) {
  if (varchar.length > longitud_maxima) {
    let result = []
    let string = ''
    let cadena = varchar.split(' ')

    for (const palabra of cadena) {
      let aux = string + palabra
      if (aux.length > longitud_maxima) {
        result.push(string)
        string = `${palabra} `
      } else {
        string += `${palabra} `
      }
    }
    if (string.trim() != '') result.push(string)
    return result
  } else {
    return [varchar]
  }
}

function set_data_giftcardA4 (pagina, data, fuente) {
  // fecha
  pagina.drawText(moment(data.fecha).locale('es').format('DD [días de] MMMM [del] YYYY'), {
    x: 340,
    y: 677,
    size: 12,
    font: fuente,
  })
  // monto en $$
  pagina.drawText(format_money(data.importe), {
    x: 330,
    y: 650,
    size: 12,
    font: fuente,
  })
  // monto en texto
  pagina.drawText(NumerosALetras(data.importe), {
    x: 80,
    y: 621, //622
    size: 12,
    font: fuente,
  })
  // tipo de documento
  pagina.drawText(data.tipo_doc, {
    x: 318,
    y: 345,
    size: 12,
    font: fuente,
  })
  // numero de documento
  pagina.drawText(data.numero_doc.toString(), {
    x: 355,
    y: 345,
    size: 12,
    font: fuente,
  })
  // nombre y apellido

  // si es muy largo lo recorta y hace un "salto de linea"
  const nombre = salto_linea(data.nombre, 20)

  pagina.drawText(nombre.parte1, {
    x: 355,
    y: 318,
    size: 12,
    font: fuente,
  })

  pagina.drawText(nombre.parte2, {
    x: 355,
    y: 300,
    size: 12,
    font: fuente,
  })

  // tipo de entidad
  pagina.drawText(data.tipo_entidad, {
    x: 292,
    y: 272,
    size: 12,
    font: fuente,
  })
  // codigo de entidad
  pagina.drawText(data.codigo_entidad.toString(), {
    x: 355,
    y: 272,
    size: 12,
    font: fuente,
  })
  // numero de comprobante
  pagina.drawText(data.numero_comprobante.toString(), {
    x: 350,
    y: 244,
    size: 12,
    font: fuente,
  })
}

// devuelve un array buffer del pdf
async function giftcardA4 (payload) {

  // obtiene el pdf que esta almacenado en la api
  const existingPdfBytes = await get_pdf('gift-card')

  const pdfDoc = await PDFDocument.load(existingPdfBytes)
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

  const pages = pdfDoc.getPages()
  const firstPage = pages[0]

  set_data_giftcardA4(firstPage, payload, helveticaFont)

  const pdfBytes = await pdfDoc.save()

  return pdfBytes.buffer

}

// devuelve un pdf con todos los comprobantes de las giftcards
async function giftcardA4_masiva (data) {

  // obtiene el pdf que esta almacenado en la api
  const existingPdfBytes = await get_pdf('gift-card')
  const pdfOriginal = await PDFDocument.load(existingPdfBytes)

  // crea un nuevo pdf en blanco
  const pdfDoc = await PDFDocument.create()

  for (const index in data) {
    const item = data[index]
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    // añade una copia de la pagina del pdf original al nuevo pdf
    const [copia] = await pdfDoc.copyPages(pdfOriginal, [0])
    pdfDoc.addPage(copia)

    const pages = pdfDoc.getPages()
    const page = pages[index]

    set_data_giftcardA4(page, item, helveticaFont)

  }

  const pdfBytes = await pdfDoc.save()

  return pdfBytes.buffer

}

// devuelve un array buffer del pdf
async function reciboEfDycarA4 (data) {

  // obtiene el pdf que esta almacenado en la api
  const existingPdfBytes = await get_pdf(`recibo-efectivo-${data.empresa == 15 ? 'euro' : ''}dycar`)

  const pdfDoc = await PDFDocument.load(existingPdfBytes)
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
  const helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

  const pages = pdfDoc.getPages()
  const firstPage = pages[0]

  // SET LOGO
  const jpgImageBytes = await get_file('/logos/' + data.logo, 'image/jpeg')

  const jpgImage = await pdfDoc.embedJpg(jpgImageBytes)
  const jpgDims = jpgImage.scale(0.2)

  firstPage.drawImage(jpgImage, {
    x: 80,
    y: data.empresa == 15 ? 725 : 715,
    width: jpgDims.width,
    height: jpgDims.height,
  })

  // SET DATA
  // numero
  firstPage.drawText(data.numero, {
    x: 372,
    y: 721,
    size: 13,
    font: helveticaBold,
  })
  // fecha
  firstPage.drawText(data.fecha, {
    x: 390,
    y: 686,
    size: 10,
    font: helveticaFont,
  })
  // vendedor
  if (data.vendedor) {
    const vendedor = salto_linea(data.vendedor, 14)
    firstPage.drawText(vendedor.parte1, {
      x: 405,
      y: 668,
      size: 10,
      font: helveticaFont,
    })
    firstPage.drawText(vendedor.parte2, {
      x: 405,
      y: 653,
      size: 10,
      font: helveticaFont,
    })
  }
  // razon social
  firstPage.drawText(data.razon_social.toString(), {
    x: 146,
    y: 686,
    size: 10,
    font: helveticaFont,
  })
  // cuit
  firstPage.drawText(data.cuit.toString(), {
    x: 110,
    y: 668,
    size: 10,
    font: helveticaFont,
  })
  // domicilio
  firstPage.drawText(data.domicilio.toString(), {
    x: 128,
    y: 651,
    size: 10,
    font: helveticaFont,
  })
  // telefono
  firstPage.drawText(data.telefono.toString(), {
    x: 126,
    y: 634,
    size: 10,
    font: helveticaFont,
  })
  // local
  firstPage.drawText(data.local, {
    x: 80,
    y: 618,
    size: 10,
    font: helveticaFont,
  })
  // cliente
  firstPage.drawText(data.cliente, {
    x: 112,
    y: 560,
    size: 11,
    font: helveticaFont,
  })
  // dni
  firstPage.drawText(data.documento, {
    x: 71,
    y: 540,
    size: 11,
    font: helveticaFont,
  })
  // sol ad
  firstPage.drawText(data.suscripcion, {
    x: 135,
    y: 522,
    size: 11,
    font: helveticaFont,
  })
  // monto en $
  firstPage.drawText(format_money(data.importe), {
    x: 380,
    y: 387,
    size: 11,
    font: helveticaFont,
  })
  // monto en texto
  const importe_texto = salto_linea(NumerosALetras(data.importe), 40)
  firstPage.drawText(importe_texto.parte1, {
    x: 98,
    y: 358,
    size: 11,
    font: helveticaFont,
  })
  firstPage.drawText(importe_texto.parte2, {
    x: 99,
    y: 342,
    size: 11,
    font: helveticaFont,
  })
  // total
  firstPage.drawText(format_money(data.importe), {
    x: 460,
    y: 358,
    size: 11,
    font: helveticaBold,
  })

  const pdfBytes = await pdfDoc.save()

  return pdfBytes.buffer

}

async function reporteControlStock (data) {

  // creo la primer hoja del pdf (por defecto genera una a4)
  let doc = new jsPDF({ putOnlyUsedFonts: false })

  // defino las medidass de la hoja
  const width = 210  // ancho de 21 cm
  const height = 297 // alto de 29,7 cm
  const margin = 25  // margenes de 2,5 cm

  ////**  DATOS DE LA CABECERA  *////

  doc.setFont('helvetica', 'bold')
  doc.setFontSize(12)

  doc.text('CONTROL DE STOCK', 80, 30)

  doc.setFont('helvetica', 'normal')
  doc.setFontSize(10)

  const titulo = salto_linea(`Control Nº: ${data.nombre}`, 80)
  let alto = 50
  doc.text(titulo.parte1, margin, alto)
  if (titulo.parte2 != '') {
    alto += 5
    doc.text(titulo.parte2, margin + 18, alto)
  }
  doc.text(`Fecha: ${data.fecha}`, 150, 45)
  doc.text(`Sucursal: ${data.sucursal} - Bodega-Local: ${data.bodega}`, margin, alto + 5)
  doc.text(`Usuario grabación: ${data.usuario}`, margin, alto + 10)
  const responsable = salto_linea(data.responsable, 18)
  doc.text(`Controlaron: ${responsable.parte1}`, 130, alto + 10)
  doc.text(responsable.parte2, 150.5, alto + 15)

  ////**  TABLA DE CATEGORIAS CONTROLADAS  *////

  doc.line(margin, alto + 20, width - margin, alto + 20)
  doc.line(margin, alto + 21, width - margin, alto + 21)

  doc.text('Categorías controladas', margin, alto + 26)

  doc.table(margin, alto + 30, data.categorias,
    [
      { id: 'nombre', name: 'nombre', prompt: 'Categoría', width: 60, align: 'center' },
      { id: 'total', name: 'total', prompt: 'Total Art.', width: 30, align: 'center' }
    ],
    { autoSize: false, fontSize: 8, padding: 1 }
  )

  // el alto base seria el alto del ultimo elemento insertado en el pdf, en este caso la tabla
  alto += 30

  // cada fila mide aprox 5.5 cm, por lo tanto para calcular el alto total de la tabla debemos multiplicar 6 * la longitud del vector + 1 (del encabezado)
  alto += 6 * (data.categorias.length + 1)

  ////** ESTABLEZCO LOS ENCABEZADOS DE LAS SIGIENTES TABLAS EN BASE A SI SOLICITA SERIE O NO *////
  let cabecera = []
  if (data.serie == 1) {
    cabecera = [
      { id: 'codigo', name: 'codigo', prompt: 'Código', width: 16, align: 'center' },
      { id: 'articulo', name: 'articulo', prompt: 'Artículo', width: 60, align: 'center' },
      { id: 'categoria', name: 'categoria', prompt: 'Categoría', width: 30, align: 'center' },
      { id: 'serie', name: 'serie', prompt: 'Serie', width: 40, align: 'center' },
      { id: 'observacion', name: 'observacion', prompt: 'Observaciones', width: 70, align: 'center' }
    ]
  } else {
    cabecera = [
      { id: 'codigo', name: 'codigo', prompt: 'Código', width: 16, align: 'center' },
      { id: 'articulo', name: 'articulo', prompt: 'Artículo', width: 60, align: 'center' },
      { id: 'categoria', name: 'categoria', prompt: 'Categoría', width: 30, align: 'center' },
      { id: 'stock_virtual', name: 'stock_virtual', prompt: 'Cant. Virt.', width: 14, align: 'center' },
      { id: 'stock_real', name: 'stock_real', prompt: 'Cant. Física', width: 14, align: 'center' },
      { id: 'diferencia', name: 'diferencia', prompt: 'Dif.', width: 12, align: 'center' },
      { id: 'observacion', name: 'observacion', prompt: 'Observaciones', width: 70, align: 'center' }
    ]
  }

  ////**  TABLA DE FALTANTES  *////

  const faltantes = data.detalle.filter(f => f.estado == 4)
  // solo agrega la tabla si existen faltantes
  if (faltantes.length > 0) {
    doc.line(margin, alto + 5, width - margin, alto + 5)
    doc.line(margin, alto + 6, width - margin, alto + 6)

    doc.setFontSize(10)
    doc.text('Detalle Faltantes', margin, alto + 11)

    doc.table(margin, alto + 15, faltantes, cabecera, { autoSize: false, fontSize: 8, padding: 1 })
  
    // sumamos al alto la altura inicial del ultimo componente (la tabla)
    alto += 15

    // calculamos el nuevo alto teniendo en cuenta que cada fila mide en promedio aporx 1,025 cm
    alto += 10 * (faltantes.length + 1)
  }

  ////**  TABLA DE SOBRANTES  *////

  const sobrantes = data.detalle.filter(f => f.estado == 3)
  // solo agrega la tabla si existen sobrantes
  if (sobrantes.length > 0) {
    // alto total de la tabla
    const alto_tabla = 10 * (sobrantes.length + 1)

    // verificamos si el alto de la tabla que estamos por insertar es mayor que el alto del margen inferior
    if (alto + 15 + alto_tabla > height - margin) {
      // en caso de ser mas alto, debemos hacer un salto de pagina ya que la tabla no entra en el espacio que queda
      doc.addPage()
      alto = 20
    }

    doc.line(margin, alto + 5, width - margin, alto + 5)
    doc.line(margin, alto + 6, width - margin, alto + 6)

    doc.setFontSize(10)
    doc.text('Detalle Sobrantes', margin, alto + 11)

    doc.table(margin, alto + 15, sobrantes, cabecera, { autoSize: false, fontSize: 8, padding: 1 })

    // sumamos al alto la altura inicial de la tabla
    alto += 15
    // sumamos el alto de la tabla
    alto += alto_tabla
  }

  ////**  FINAL DEL DOCUMENTO  *////

  // verifica si el alto es mayor o igual al margen inferior de la pagina
  if (alto >= height - margin) {
    // si es asi hace un salto de pagina
    doc.addPage()
    alto = 20
  }

  doc.line(margin, alto + 5, width - margin, alto + 5)
  doc.line(margin, alto + 6, width - margin, alto + 6)

  doc.setFontSize(10)
  doc.text('En presencia de:', margin, alto + 15)
  doc.text('FIRMA:', 90, alto + 15)
  doc.text('ACLARACION:', 150, alto + 15)

  // devuelve los bytes del pdf generado
  return doc.output('arraybuffer')

}

async function planillaArtStock (articulos, id, bodega) {

  let alto = 30
  let cabecera = [
    { id: 'codigo', name: 'codigo', prompt: 'Código', width: 20, align: 'center' },
    { id: 'articulo', name: 'articulo', prompt: 'Artículo', width: 160, align: 'left' },
    { id: 'stock_virtual', name: 'stock_virtual', prompt: 'Cant. Virt.', width: 15, align: 'center' },
    { id: 'stock_real', name: 'stock_real', prompt: 'Cant. Física', width: 15, align: 'center' }
  ]

  // creo la primer hoja del pdf (por defecto genera una a4)
  let doc = new jsPDF({ putOnlyUsedFonts: false })

  // defino las medidass de la hoja
  const width = 210  // ancho de 21 cm
  const height = 297 // alto de 29,7 cm
  const margin = 25  // margenes de 2,5 cm

  ////**  DATOS DE LA CABECERA  *////

  doc.setFont('helvetica', 'bold')
  doc.setFontSize(12)

  doc.text('CONTROL DE STOCK ' + id + ' - ' + bodega, margin, alto)

  doc.setFont('helvetica', 'normal')

  // agrupo los articulos por categoria y creo una tabla para cada categoria
  let group_by_categoria = articulos.reduce(function (r, a) {
    r[a.categoria] = r[a.categoria] || []
    r[a.categoria].push(a)
    return r
  }, Object.create(null))
  
  // ciclo for por cada categoria
  for (const categoria in group_by_categoria) {
    let group = group_by_categoria[categoria]

    // ordena por codigo
    order_list_by(group, 'codigo')
    // pasa los codigos de los art de numerico a string
    group.forEach(art => art.codigo = art.codigo.toString())

    // calculamos el ato de la tabla (6 corresponde a cada fila y 9 al encabezado)
    const alto_tabla = (5.5 * group.length) + 8.5

    // verificamos si el alto de la tabla que estamos por insertar es mayor que el alto del margen inferior
    if ((alto + 15 + alto_tabla > height - margin) && alto != 30) {
      // en caso de ser mas alto, debemos hacer un salto de pagina ya que la tabla no entra en el espacio que queda
      doc.addPage()
      alto = 20
    }
    
    doc.line(margin, alto + 5, width - margin, alto + 5)
    doc.line(margin, alto + 6, width - margin, alto + 6)

    doc.setFontSize(10)
    doc.text(categoria, margin, alto + 11)

    doc.table(margin, alto + 15, group, cabecera, { autoSize: false, fontSize: 8, padding: 1 })

    // sumamos al alto la altura inicial del ultimo componente (la tabla)
    // calculamos el nuevo alto teniendo en cuenta que cada fila mide en promedio aporx 1,025 cm
    alto += alto_tabla + 15
  }

  // descarga el archivo
  return doc.save('Artículos - Control de Stock ' + id)

}

async function reporteParaguay (data) {
  
  // obtengo las imagenes
  const logoBB = ArrayBufferToBase64(await get_file('/logos/logoBB_paraguay.jpg', 'image/jpeg'))
  const logoPersonal = ArrayBufferToBase64(await get_file('/logos/personal_flow.jpg', 'image/jpeg'))

  // defino las medidas de la hoja
  const width = 210  // ancho de 21 cm
  const height = 297 // alto de 29,7 cm
  const margin_x = 15  // margenes laterales de 1,5 cm
  const margin_top = 20  // margen superior de 2,5 cm
  const margin_bottom = 15  // margen inferior de 1,5 cm

  const limite_inferior = height - margin_bottom
  const gris_header = '#e1e1e1' //dcdcdc

  // creo la primer hoja del pdf (por defecto genera una a4)
  let doc = new jsPDF({ putOnlyUsedFonts: false })

  /**
   *  Nota: cada row de las tablas que tengan 0.5 de padding, miden exactamente 4.25 de alto
   *        con este valor se calcula el alto total de la tabla en base a la longitud + 1 del array
   *        se le suma 1 por los encabezados
   */
  const alto_row = 4.25

  // funcion para setear el encabezado
  function setEncabezado () {
    doc.addImage(logoBB, "JPEG", margin_x, 6)
    doc.addImage(logoPersonal, "JPEG", 132, 8)
  }

  ////**  HEADERS PARA LAS TABLAS  *////
  const header_prepagos = [
    { id: 'concepto', name: 'concepto', prompt: 'Concepto', width: 23, align: 'left' },
    { id: 'linea', name: 'linea', prompt: 'Linea', width: 25, align: 'left' },
    { id: 'fecha_activacion', name: 'fecha_activacion', prompt: 'Fecha Act', width: 25, align: 'left' },
    { id: 'fecha_aprobacion', name: 'fecha_aprobacion', prompt: 'Fecha Aprob', width: 25, align: 'left' },
    { id: 'estado', name: 'estado', prompt: 'Estado', width: 20, align: 'left' },
    { id: 'comision_carga_inicial', name: 'comision_carga_inicial', prompt: 'Com Carga Ini', width: 27, align: 'right' },
    { id: 'monto', name: 'monto', prompt: 'Monto', width: 20, align: 'right' },
    { id: 'comision_recarga', name: 'comision_recarga', prompt: 'Com Carga Ini', width: 27, align: 'right' },
    { id: 'bonificacion_portabilidad', name: 'bonificacion_portabilidad', prompt: 'Bonif Porta', width: 25, align: 'right' },
    { id: 'total', name: 'total', prompt: 'Total', width: 20, align: 'right' }
  ]
  const header_otros = [
    { id: 'concepto', name: 'concepto', prompt: 'Detalle', width: 192, align: 'left' },
    { id: 'cantidad', name: 'cantidad', prompt: 'Cantidad', width: 20, align: 'center' },
    { id: 'importe', name: 'importe', prompt: 'Importe', width: 25, align: 'right' }
  ]

  for (let index = 0; index < data.length; index++) {
    const item = data[index]

    // siempre que no sea la primer pagina del documento empiza con un salto de pagina
    if (index !== 0) {
      doc.addPage()
    }

    ////**  ENCABEZADO  *////
    setEncabezado()

    /*doc.line(margin_x, margin_top - 5, width - margin_x, margin_top - 5)
    doc.line(margin_x, margin_top, width - margin_x, margin_top)
    doc.line(margin_x, 0, margin_x, margin_top)
    doc.line(width - margin_x, 0, width - margin_x, margin_top)
    doc.line(width - (width/2), 0, width - (width/2), margin_top)

    doc.line(margin_x, height - margin_bottom, width - margin_x, height - margin_bottom)
    doc.line(margin_x, height, margin_x, height - margin_bottom)
    doc.line(width - margin_x, height, width - margin_x, height - margin_bottom)
    doc.line(width - (width/2), height, width - (width/2), height - margin_bottom)*/

    ////**  DATOS DE LA CABECERA  *////
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(10)
    doc.text(`Liquidación Nº: ${item.num_liqui}`, margin_x, margin_top + 5)
    doc.text(`Periodo: ${item.periodo}`, margin_x + 150, margin_top + 5)
    doc.text(`IVR: ${item.ivr} - ${item.ptovta_nombre}`, margin_x, margin_top + 11)
    doc.text(`Supervisor: ${item.supervisor_nombre}`, margin_x, margin_top + 17)

    ////**  DEALLE PREPAGOS  *////
    doc.setFontSize(9)
    doc.text('Detalle PREPAGOS', margin_x, margin_top + 25)
    
    /**
     *  La funcion maestra se encarga de insertar las tablas en las paginas correspondientes generando
     *  los saltos de pagina necesarios
     */
    let y_final_elemento = 0
    function funcionMaestra (posicion_inicial_tabla, array, headers) {
      // inicializacion de variables
      y_final_elemento = 0
      let y_inicial_tabla = posicion_inicial_tabla
      let total_rowsXpagina = parseInt((limite_inferior - y_inicial_tabla) / alto_row)
      let subarray = array

      // si el total de rows x pagina es negativo o 0 significa que la tabla no entra en lo que queda de espacio en la hoja
      if (total_rowsXpagina <= 0) {
        total_rowsXpagina = 0
        // si la longitud del array es 0 entonces debo isnertar la cabecera de la tabla ya que en el siguiente "if (y_final_elemento === 0)" no insertaria nada
        if (array.length == 0) {
          doc.addPage()
          setEncabezado()
          // INSERTO LA TABLA
          doc.table(margin_x, margin_top, subarray, headers, { autoSize: false, fontSize: 8, padding: 0.5, headerBackgroundColor: gris_header })
          y_final_elemento = alto_row + margin_top
        }
      }
      // si el total de rows es mayor a 0 significa que la tabla entera o parte de ella entra en el espacio que queda
      else {
        // VERIFICO SI EL TOTAL DE ROWS X PAGINA ES MENOR QUE LA LONGITUD TOAL DEL ARRAY + 1 (por el encabezado)
        if (total_rowsXpagina < array.length + 1) {
          /**
           *  Si el total de rows x pagina ex menor a la longitud + 1 significa que la tabla no entra en lo que queda
           *  de espacio en la pagina, por lo tanto debo recortar el array
           */
          subarray = array.slice(0, total_rowsXpagina - 1)
        }
        // caso contrario indico el alto final de la tabla
        else {
          y_final_elemento = ((array.length + 1) * alto_row) + y_inicial_tabla
        }

        // INSERTO LA TABLA
        doc.table(margin_x, y_inicial_tabla, subarray, headers, { autoSize: false, fontSize: 8, padding: 0.5, headerBackgroundColor: gris_header })
      }

      // SI EL ALTO FINAL DE LA TABLA ES 0 SIGNIFICA QUE LA TABLA NO ENTRÓ (parcial o totalmente) EN LO QUE QUEDABA DE PAGINA Y SE DEBE HACER UN SALTO
      if (y_final_elemento === 0) {
        
        // obtengo el resto de elementos del array que no se insertaron
        subarray = array.slice(total_rowsXpagina, array.length)
        // calculo nuevamente la cantidad de filas que puedo inserar por hoja
        total_rowsXpagina = parseInt((limite_inferior - margin_top) / alto_row)
        // separo el subarray en grupos que contengan la longitud maxima que permite insertar la tabla en 1 hoja
        const subarray_stack = divideArray(subarray, total_rowsXpagina - 1)

        // recorro los grupos de arrays para insertar las tablas en las paginas
        for (let index = 0; index < subarray_stack.length; index++) {
          const stack = subarray_stack[index]
          doc.addPage()
          // isnerta el encabezado
          setEncabezado()
          // inserta la tabla
          doc.table(margin_x, margin_top, stack, headers, { autoSize: false, fontSize: 8, padding: 0.5, headerBackgroundColor: gris_header })
          // Si se esta insertando la ultima tabla hay que obtener el alto final de la misma para insertar el siguiente elemento a la misma altura
          if (index == subarray_stack.length - 1) {
            y_final_elemento = ((stack.length + 1) * alto_row) + margin_top
          }
        }
      }
    }

    // INSERTAMOS LA TABLA
    funcionMaestra(margin_top + 28, item.prepagos, header_prepagos)

    // VERIFICAMOS QUE EL ALTO DEL SIGUIENTE ELEMENTO ENTRE EN LO QUE QUEDA DE PAGINA sino hacemos un salto de pagina
    function verificarSaltoPagina (altura) {
      if (y_final_elemento + altura > limite_inferior) {
        doc.addPage()
        setEncabezado()
        y_final_elemento = margin_top
      }
    }
    verificarSaltoPagina(5)
    
    // total de la tabla
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(9)
    doc.text(`Total PREPAGOS: ${item.total_prepagos}`, margin_x, y_final_elemento + 5)

    // seteamos la nueva altura del ultimo componente
    y_final_elemento += 5

    for (let index = 0; index < item.otros.length; index++) {
      const otro_concepto = item.otros[index]
      
      // SOLO SI TIENE DETALLE EL OTRO CONCEPTO LO INSERTA
      if (otro_concepto.detalle.length > 0) {
        // inserta el titulo del otro concepto
        verificarSaltoPagina(8)
        doc.setFont('helvetica', 'bold')
        doc.setFontSize(9)
        doc.text(`Detalle ${otro_concepto.nombre}`, margin_x, y_final_elemento + 8)
        y_final_elemento += 8

        // INSERTA LA TABLA del concepto
        funcionMaestra(y_final_elemento + 3, otro_concepto.detalle, header_otros)
        verificarSaltoPagina(5)

        // total de la tabla
        doc.setFont('helvetica', 'bold')
        doc.setFontSize(9)
        doc.text(`Total ${otro_concepto.nombre}: ${otro_concepto.total}`, margin_x, y_final_elemento + 5)
        // seteamos la nueva altura del ultimo componente
        y_final_elemento += 5
      }

    }

    verificarSaltoPagina(30)
    // tabla de los totales
    doc.table(margin_x + 8, y_final_elemento + 10, [],
      [
        { id: 'nombre', name: 'nombre', prompt: 'Subtotal', width: 30, align: 'right' },
        { id: 'total', name: 'total', prompt: item.subtotal.toString(), width: 40, align: 'right' }
      ],
      { autoSize: false, fontSize: 10, padding: 1, headerBackgroundColor: '#ffffff' }
    )
    doc.table(margin_x + 8, y_final_elemento + 16, [],
      [
        { id: 'nombre', name: 'nombre', prompt: 'IVA', width: 30, align: 'right' },
        { id: 'total', name: 'total', prompt: item.iva.toString(), width: 40, align: 'right' }
      ],
      { autoSize: false, fontSize: 10, padding: 1, headerBackgroundColor: '#ffffff' }
    )
    doc.table(margin_x + 8, y_final_elemento + 22, [],
      [
        { id: 'nombre', name: 'nombre', prompt: 'Total', width: 30, align: 'right' },
        { id: 'total', name: 'total', prompt: item.total.toString(), width: 40, align: 'right', headerBackgroundColor: gris_header }
      ],
      { autoSize: false, fontSize: 10, padding: 1 }
    )
    // linea para tapar la linea entre subtotal e iva
    doc.setLineWidth(0.5)
    doc.setDrawColor('#ffffff')
    doc.line(margin_x + 8.1, y_final_elemento + 16, margin_x + 30.4, y_final_elemento + 16)
    doc.line(margin_x + 30.6, y_final_elemento + 16, margin_x + 60.4, y_final_elemento + 16)

    // agrega la firma
    doc.setFont('helvetica', 'bold')
    doc.text('Firma', margin_x + 110, y_final_elemento + 15)
    doc.setLineWidth(0.25)
    doc.setDrawColor('#000000')
    doc.line(margin_x + 110, y_final_elemento + 30, width - margin_x - 15, y_final_elemento + 30)
    
  }

  // agrega los numeros de pagina al final de cada pagina
  for (let index = 1; index <= doc.getNumberOfPages(); index++) {
    doc.setPage(index)
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(10)
    doc.text(`Página ${index} de ${doc.getNumberOfPages()}`, 94, height - 6)
  }

  // devuelve los bytes del pdf generado
  return doc.output('arraybuffer')
}

async function reporteRemitoInterno(data){
  try{
    // obtengo el logo de BB
    const logoBB = ArrayBufferToBase64(await get_file('/logos/logo_bb.png', 'image/png'))
    // defino las medidas de la hoja
    //const width = 210  // ancho de 21 cm
    const height = 297 // alto de 29,7 cm
    const margin_x = 15  // margenes laterales de 1,5 cm
    const margin_top = 20  // margen superior de 2,5 cm
    //const margin_bottom = 15  // margen inferior de 1,5 cm
    const widthImg = 40
    const heightImg = 20

    //const limite_inferior = height - margin_bottom
    const gris_header = '#e1e1e1'

    // creo la primer hoja del pdf (por defecto genera una a4)
    let doc = new jsPDF({ putOnlyUsedFonts: false })

    const alto_row = 4.25
    // 
    function setEncabezado () {
      // tazo el recuadro
      // lineas horizontales
      doc.line(margin_x, margin_x, 195, margin_x)
      doc.line(margin_x, margin_x + 22, 195, margin_x + 22)
      doc.line(margin_x, margin_x + 38, 195, margin_x + 38)
      doc.line(margin_x, margin_x + 45, 195, margin_x + 45)
      // linas verticales
      doc.line(margin_x, margin_x, margin_x, margin_x + 45)
      doc.line(195, margin_x, 195, margin_x + 45)
      // lineas para la X
      doc.line(95, margin_x, 95, margin_x + 15)
      doc.line(110, margin_x, 110, margin_x + 15)
      doc.line(95, margin_x + 15, 110, margin_x + 15)
      // la X
      doc.setFontSize(30)
      doc.text('X', 99, margin_x + 11)
      // logo de BB
      doc.addImage(logoBB, "PNG", margin_x + 15, 15, widthImg, heightImg)
      doc.setFontSize(7)
      // documento no valido como factura
      doc.text('Documento no válido como Factura', margin_x + 100, margin_x + 3)
      // Remito
      doc.setFontSize(20)
      doc.text('REMITO', 162.5, margin_x + 7.5)
      // recuadro de la Remito
      doc.line(160, margin_x, 160, margin_x + 10)
      doc.line(192, margin_x, 192, margin_x + 10)
      doc.line(160, margin_x + 10, 192, margin_x + 10)
      // Remito N°
      let emision = 1
      let numero = data.enc.remito_numero
      if (data.enc.emision != 0){
        emision = data.enc.emision
        numero = data.enc.numero
      }
      let nroRemito = String(emision).padStart(4, '0') + '-' + String(numero).padStart(8, '0')
      doc.setFontSize(10)
      doc.text('N°: ' + nroRemito, 161, margin_x + 15)
      // Fecha
      doc.text('Fecha: ' + data.enc.fecha.toString(), 161, margin_x + 20)
      // bodega origen y bodega destino
      doc.setFontSize(8)
      doc.text('Bodega Origen: ' + data.enc.bod_origen_nom, margin_x + 3, margin_x + 28)
      doc.text('Bodega Destino: ' + data.enc.bod_destino_nom, margin_x + 90, margin_x + 28)
      // fecha de grabacion
      doc.text('Fecha Grabación: ' + data.enc.fecha_grabacion.toString(), margin_x + 3, margin_x + 34)
      // usuario de grabacion
      doc.text('Usuario de Grabación: ' + data.enc.usuario_grabacion, margin_x + 90, margin_x + 34)
      // referencia
      doc.text('Referencia: ' + data.enc.referencia, margin_x + 3, margin_x + 42.5)
    }
    function convertDecimals(numero){
      return parseFloat(parseFloat(numero.toString().replace(',', '.')).toFixed(2).toString().replace(',', '.'))
    }
    // seteo el estilo de fonts
    doc.setFont('helvetica', 'normal')
    // seteo el encabezado de la primera pagina
    setEncabezado()
    // encabezado para tabla normal
    let headerDetalles = [
      { id: 'articulo_codigo', name: 'articulo_codigo', prompt: 'Artículo Código', width: 30, align: 'right' },
      { id: 'articulo_nombre', name: 'articulo_nombre', prompt: 'Descripción', width: 192, align: 'left' },
      { id: 'cantidad', name: 'cantidad', prompt: 'Cantidad', width: 18, align: 'right' }
    ]

    // encabezado para tablas de franquicias
    let headerFranquicia = [
      { id: 'articulo_codigo', name: 'articulo_codigo', prompt: 'Artículo Código', width: 30, align: 'right' },
      { id: 'articulo_nombre', name: 'articulo_nombre', prompt: 'Descripción', width: 132, align: 'left' },
      { id: 'cantidad', name: 'cantidad', prompt: 'Cantidad', width: 18, align: 'right' },
      { id: 'precio', name: 'precio', prompt: 'P. Unit.', width: 30, align: 'right' },
      { id: 'importe', name: 'importe', prompt: 'Importe', width: 30, align: 'right' }
    ]

    // determino la cantidad de de la tabla por paginas
    let cant_paginas = 0
    if (parseInt(data.detalles.length) % 50 == 0){
      cant_paginas = parseInt(data.detalles.length)/50
    }else{
      cant_paginas = parseInt(parseInt(data.detalles.length)/50) + 1
    }
    // defino mi indice para tomar los primeros elementos
    let index = 0
    let total = 0
    let y_final_elemento = 0
    for (let id = 1; id <= cant_paginas; id++){
      // tomo 50 elementos
      let detallesNuevo = data.detalles.slice(index, index + 50)
      if (detallesNuevo.length > 0){
        // acumulo en total
        for (let jd in detallesNuevo){
          total = convertDecimals(convertDecimals(total) + convertDecimals(detallesNuevo[jd].importe))
          // coloco el precio con notacion numerica
          detallesNuevo[jd].importe = format_money(detallesNuevo[jd].importe)
          detallesNuevo[jd].importe = detallesNuevo[jd].importe.toString()
          detallesNuevo[jd].precio = format_money(detallesNuevo[jd].precio)
          detallesNuevo[jd].precio = detallesNuevo[jd].precio.toString()
        }
        // obtengo la posicion de y
        y_final_elemento = margin_top + 50 + (alto_row * (detallesNuevo.length + 1))
        // inserto el encabezado si el index distinto de cero, pues el encabezado de la primera pagina se coloca al principio
        if (index != 0){
          doc.addPage()
          setEncabezado()
        }
        if (data.enc.terceros == 1){
          doc.table(margin_x, margin_top + 45, detallesNuevo, headerFranquicia, { autoSize: false, fontSize: 8, padding: 0.5, headerBackgroundColor: gris_header })
        }else{
          doc.table(margin_x, margin_top + 45, detallesNuevo, headerDetalles, { autoSize: false, fontSize: 8, padding: 0.5, headerBackgroundColor: gris_header })
        }
      }
      index = index + detallesNuevo.length
    }
    // cuando salga del ciclo controlo si debo totalizar los detalles
    if (data.enc.terceros == 1){
      // controlo mi index para ver si tengo que hacer un salto de pagina
      if (parseInt(index) % 50 == 0){
        // hago un salto de pagina
        doc.addPage()
        setEncabezado()
      }
      // paso el total a string
      total = format_money(total)
      total = total.toString()
      // inserto la tabla
      // encabezado para totalizar
      let headerTotales = [
        { id: 'total_nom', name: 'total_nom', prompt: 'Total', width: 210, align: 'right' },
        { id: 'total', name: 'total', prompt: total, width: 30, align: 'right' },
      ]
      doc.table(margin_x, y_final_elemento, [], headerTotales, { autoSize: false, fontSize: 8, padding: 0.5, headerBackgroundColor: gris_header })
    }
    // agrega los numeros de pagina al final de cada pagina
    for (let index = 1; index <= doc.getNumberOfPages(); index++) {
      doc.setPage(index)
      doc.setFont('helvetica', 'normal')
      doc.setFontSize(10)
      doc.text(`Página ${index} de ${doc.getNumberOfPages()}`, 94, height - 6)
    }
    return {
      resultado: 1,
      msj: 'OK',
      pdf: doc.output('arraybuffer')
    }
  }catch(error){
    return {
      resultado: 0,
      msj: 'Ocurrio un problema al ejecutar el metodo reporteRemitoInterno: ' + error.message,
      pdf: null
    }
  }
}

async function libroIvaPdf(data){
  try{
    // creo la primer hoja del pdf (por defecto genera una a4)
    let doc = new jsPDF({ putOnlyUsedFonts: false })
    // defino las medidass de la hoja, ancho de 21 cm, alto de 29,7 cm, margenes de 2,5 cm
    const width = 210
    const height = 297
    const margin = 15
    // datos de cabecera
    function setEncabezado(){
      doc.setFont('helvetica', 'bold')
      doc.setFontSize(12)

      // titulo
      doc.text('LIBRO I.V.A. VENTAS', 83, 30)
      doc.text('DEL ' + data.fecha_desde + ' HASTA ' + data.fecha_hasta, 70, 35)

      // datos que acompañan al título: fecha que se genera el reporte, Folio, datos de empresa
      doc.setFont('courier', 'normal')
      doc.setFontSize(10)
      doc.text(data.empresa.nombre.toUpperCase(), margin, 13)
      doc.text('BELGRANO', margin, 17)
      doc.text('Cuit: ' + data.empresa.cuit, margin, 21)
      doc.text('Responsable Inscripto', margin, 25)
      // fecha y folio
      doc.text('Fecha: ' + moment(new Date()).format("DD/MM/YYYY"), 160, 13)
      doc.text('Folio: 0', 160, 17)
      // nombres de columnas
      doc.setFont('tahoma', 'bold')
      doc.text('Referencia', margin - 8, 45)
      doc.text('Cliente', margin + 20, 45)
      doc.text('Cuit', margin + 45, 45)
      doc.text('Total', margin + 62, 45)
      doc.text('No Grab./\r\n Exento', margin + 80, 45)
      doc.text('Neto 10,5%', margin + 100, 45)
      doc.text('Iva 10,5%', margin + 120, 45)
      doc.text('Neto 21%', margin + 140, 45)
      doc.text('Iva 21%', margin + 160, 45)
      doc.text('Reten.', margin + 180, 45)
    }
    // control de hoja nueva
    function controlNuevaHoja(pos_y, alto_linea){
      if ((pos_y + alto_linea) > height){
        // inserto una nueva pagina
        doc.addPage()
        // inserto el encabezado
        setEncabezado()
        // respuesta
        return true
      }
      return false
    }
    // dada una linea string, devuelvo la linea con salto de linea
    function saltoLinea(linea, largo){
      if (linea == null || linea == undefined){
        return ''
      }else{
        // controlo los renglones, solo permito 2
        let renglon = 1
        // separo la linea en un array de palabras
        let palabras = linea.toString().split(' ')
        let cadena = ''
        for (let id in palabras){
          if (renglon <= 2){
            if ((cadena + palabras[id]).length  <= parseInt(largo)*renglon){
              cadena = cadena + palabras[id]
              if (parseInt(id) < parseInt(palabras.length) - 1){
                // tengo que agregar un espacio
                if ((cadena + ' ').length <= parseInt(largo)*renglon){
                  cadena = cadena + ' '
                }else{
                  // supera el largo permitido, concateno un salto de linea
                  renglon = renglon + 1
                  cadena = cadena + '\r\n'
                }
              }
            }else{
              renglon = renglon + 1
              cadena = cadena + '\r\n'
              if ((parseInt(id) == parseInt(palabras.length) - 1) && parseInt(renglon) <= 2){
                cadena = cadena + palabras[id]
              }
            }
          }
        }
        return cadena
      }
    }
    // inserto en encabezado en la primera pagina
    setEncabezado()
    // de entrada no puedo sacar la cantidad de hojas, voy calculando el salto de linea para insertar una nueva hoja
    let fecha_desde = moment(data.fecha_desde, "DD/MM/YYYY").toDate()
    let fecha_hasta = moment(data.fecha_hasta, "DD/MM/YYYY").toDate()
    let y_actual = 60
    let alto_linea = 6
    let largoLineaCliente = 15
    doc.setFontSize(10)
    while(fecha_desde <= fecha_hasta){
      let fecha = moment(fecha_desde).format("DD/MM/YYYY")
      // controlo si debo hacer una nueva hoja
      if (controlNuevaHoja(y_actual, alto_linea)){
        // puse una nueva hoja, seteo nuevamente el y_actual para seguir escribiendo
        y_actual = 60
        doc.setFontSize(10)
      }
      // escribo la fecha
      doc.setFont('tahoma', 'bold')
      doc.setFontSize(10)
      doc.text(fecha, margin - 10, y_actual)
      // aumento el y_actual
      y_actual = y_actual + alto_linea
      // obtengo las ventas que corresponden a la fecha
      let ventas = data.ventas.filter(element => moment(parseDate(element.fecha.toString())).format("DD/MM/YYYY") == moment(parseDate(fecha.toString())).format("DD/MM/YYYY"))
      if (ventas.length > 0){
        // recorremos las ventas para empezar a escribir
        for (let id in ventas){
          // control de hoja nueva
          if (controlNuevaHoja(y_actual, alto_linea)){
            // puse una nueva hoja, seteo nuevamente el y_actual para seguir escribiendo
            y_actual = 60
          }
          // setear la font
          doc.setFont('courier', 'normal')
          doc.setFontSize(6)
          // referencia
          doc.text(ventas[id].abreviatura + ' ' + ventas[id].emision + '-' + ventas[id].numero.toString().padStart(8, '0'), margin - 10, y_actual)
          // cliente
          doc.text(saltoLinea(ventas[id].cliente, largoLineaCliente), margin + 15, y_actual)
          // cuit
          doc.text(ventas[id].cuit, margin + 40, y_actual)
          // total
          doc.text(format_money(ventas[id].total).replace('$', '', ' ', ''), margin + 60, y_actual)
          // no grabado/exento
          doc.text(format_money(ventas[id].no_grabado).replace('$', '', ' ', ''), margin + 80, y_actual)
          // neto 10,5%
          doc.text(format_money(ventas[id].neto_105).replace('$', '', ' ', ''), margin + 100, y_actual)
          // iva 10,5%
          doc.text(format_money(ventas[id].iva_105).replace('$', '', ' ', ''), margin + 120, y_actual)
          // neto 21%
          doc.text(format_money(ventas[id].neto_21).replace('$', '', ' ', ''), margin + 140, y_actual)
          // iva 21%
          doc.text(format_money(ventas[id].iva_21).replace('$', '', ' ', ''), margin + 160, y_actual)
          // retenciones
          doc.text(format_money(ventas[id].retenciones).replace('$', '', ' ', ''), margin + 180, y_actual)
          // termino de escribir una linea, aumento mi y_actual
          y_actual = y_actual + alto_linea
        }
      }
      // termine de escribir las ventas, incremento los dias
      fecha_desde = moment(fecha, "DD/MM/YYYY").add(1, 'day').toDate()
    } 
    // devuelve los bytes del pdf generado
    return {
      resultado: 1,
      msj: 'OK',
      file: doc.output('arraybuffer')
    }
  }catch(error){
    return {
      resultado: 0,
      msj: 'Ocurrio un problema al ejecutar el metodo libroIvaPdf: ' + error.message,
      file: null
    }
  }
}

async function pdfCobroOnlineTermica(data){
  try{
    // obtengo la primer plantilla
    const existingPdfBytes = await get_pdf('pdfCompElectronicoEpsonExitosoOriginal')
    // obtengo la segunda plantilla
    const existingPdfBytess = await get_pdf('pdfCompElectronicoEpsonExitosoCopia')
    // instancio dos documentos
    const pdfDoc1 = await PDFDocument.load(existingPdfBytes)
    const pdfDoc2 = await PDFDocument.load(existingPdfBytess)
    // obtengo mi documento principal
    const pdfDoc = await PDFDocument.create()
    const [docPage1] = await pdfDoc.copyPages(pdfDoc1, [0])
    const [docPage2] = await pdfDoc.copyPages(pdfDoc2, [0])
    // inserto las paginas en mi documento principal
    pdfDoc.addPage(docPage1)
    pdfDoc.insertPage(1, docPage2)
    // empiezo a escribir
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    const pages = pdfDoc.getPages()
    for (let id = 0;id <= 1; id++){
      const actualPage = pages[id]
      // fecha
      let fecha = ''
      let fechaComp= ''
      if (!isNaN(data.tdate)){
        fecha = moment.unix(parseInt(data.tdate)).format("DD/MM/YYYY")
        fechaComp = moment.unix(parseInt(data.tdate))
      }else{
        fecha = moment(data.tdate.toString()).format("DD/MM/YYYY")
        fechaComp = moment(data.tdate.toString())
      }
      actualPage.drawText(fecha.toString(), {
        x: 25,
        y: 430,
        size: 7,
        font: helveticaFont,
      })
      // marca
      actualPage.drawText(data.marca, {
        x: 80,
        y: 430,
        size: 7,
        font: helveticaFont,
      })
      // hora
      actualPage.drawText(moment(fechaComp).format("HH:mm"), {
        x: 145,
        y: 430,
        size: 7,
        font: helveticaFont,
      })
      // empresa
      actualPage.drawText(data.empresa_nombre, {
        x: 55,
        y: 410,
        size: 7,
        font: helveticaFont,
      })
      // domicilio
      actualPage.drawText(data.empresa_domicilio, {
        x: 53,
        y: 402,
        size: 7,
        font: helveticaFont,
      })
      // cuit
      actualPage.drawText(data.empresa_cuit, {
        x: 43,
        y: 390,
        size: 7,
        font: helveticaFont,
      })
      // comercio
      let comercio = '-'
      if (data.comercio_id != null && data.comercio_id != undefined && data.comercio_id.toString().length > 0){
        comercio = data.comercio_id.toString()
      }
      actualPage.drawText(comercio, {
        x: 135,
        y: 390,
        size: 7,
        font: helveticaFont
      })
      // tarjeta
      actualPage.drawText(data.tarjeta_numero, {
        x: 90,
        y: 380,
        size: 7,
        font: helveticaFont
      })
      // titular
      actualPage.drawText(data.cliente_nombre, {
        x: 45,
        y: 370,
        size: 7,
        font: helveticaFont
      })
      // telefono
      let telefono = '-'
      if (data.cliente_telefono != null && data.cliente_telefono != undefined && data.cliente_telefono.toString().length > 0){
        telefono = data.cliente_telefono.toString()
      }
      actualPage.drawText(telefono, {
        x: 53,
        y: 360,
        size: 7,
        font: helveticaFont
      })
      // codigo autorizacion
      actualPage.drawText(data.codigo_autorizacion, {
        x: 72,
        y: 349,
        size: 7,
        font: helveticaFont
      })
      // transaccion
      actualPage.drawText(data.transaccion_id, {
        x: 61,
        y: 338,
        size: 7,
        font: helveticaFont
      })
      // numero
      actualPage.drawText(data.numero, {
        x: 50,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // lote
      let lote = ''
      if (data.lote != null && data.lote != undefined && data.lote.toString().length > 0) lote = data.lote
      actualPage.drawText(lote, {
        x: 95,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // cuotas
      actualPage.drawText(data.cuotas.toString(), {
        x: 137,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // solicitud de adhesion
      if (data.solicitud_adhesion != null && data.solicitud_adhesion != undefined && data.solicitud_adhesion.toString().length > 0){
        actualPage.drawText('Solicitud Adhesion: ' + data.solicitud_adhesion.toString(), {
          x: 26,
          y: 317,
          size: 7,
          font: helveticaFont
        })
      }
      // total
      actualPage.drawText(convertDecimals(data.importe).toString(), {
        x: 73,
        y: 304,
        size: 10,
        font: helveticaFont
      })
      // original
      if (id == 0){
        actualPage.drawText('ORIGINAL COMERCIO', {
          x: 62,
          y: 240,
          size: 8,
          font: helveticaFont
        })
      }
    }
    let pdfBytes = await pdfDoc.save()
    return {
      resultado: 1,
      msj: 'OK',
      pdf: pdfBytes.buffer
    }
  }catch(error){
    return {
      resultado: 0,
      msj: 'Ocurrio un problema al ejecutar el metodo pdfCobroOnlineTermica: ' + error.message,
      pdf: null
    }
  }
}

async function pdfCobroOnlineTermicaAnulacion(data){
  try{
    // obtengo la primer plantilla
    const existingPdfBytes = await get_pdf('pdfCompElectronicoEpsonAnulacionOriginal')
    // obtengo la segunda plantilla
    const existingPdfBytess = await get_pdf('pdfCompElectronicoEpsonAnulacionCopia')
    // instancio dos documentos
    const pdfDoc1 = await PDFDocument.load(existingPdfBytes)
    const pdfDoc2 = await PDFDocument.load(existingPdfBytess)
    // obtengo mi documento principal
    const pdfDoc = await PDFDocument.create()
    const [docPage1] = await pdfDoc.copyPages(pdfDoc1, [0])
    const [docPage2] = await pdfDoc.copyPages(pdfDoc2, [0])
    // inserto las paginas en mi documento principal
    pdfDoc.addPage(docPage1)
    pdfDoc.insertPage(1, docPage2)
    // empiezo a escribir
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    const pages = pdfDoc.getPages()
    for (let id = 0;id <= 1; id++){
      const actualPage = pages[id]
      // fecha
      let fecha = ''
      let fechaComp= ''
      if (!isNaN(data.tdate)){
        fecha = moment.unix(parseInt(data.tdate)).format("DD/MM/YYYY")
        fechaComp = moment.unix(parseInt(data.tdate))
      }else{
        fecha = moment(data.tdate.toString()).format("DD/MM/YYYY")
        fechaComp = moment(data.tdate.toString())
      }
      actualPage.drawText(fecha.toString(), {
        x: 25,
        y: 430,
        size: 7,
        font: helveticaFont,
      })
      // marca
      actualPage.drawText(data.marca, {
        x: 80,
        y: 430,
        size: 7,
        font: helveticaFont,
      })
      // hora
      actualPage.drawText(moment(fechaComp).format("HH:mm"), {
        x: 145,
        y: 430,
        size: 7,
        font: helveticaFont,
      })
      // empresa
      actualPage.drawText(data.empresa_nombre, {
        x: 55,
        y: 410,
        size: 7,
        font: helveticaFont,
      })
      // domicilio
      actualPage.drawText(data.empresa_domicilio, {
        x: 53,
        y: 402,
        size: 7,
        font: helveticaFont,
      })
      // cuit
      actualPage.drawText(data.empresa_cuit, {
        x: 43,
        y: 390,
        size: 7,
        font: helveticaFont,
      })
      // comercio
      let comercio = '-'
      if (data.comercio_id != null && data.comercio_id != undefined && data.comercio_id.toString().length > 0){
        comercio = data.comercio_id.toString()
      }
      actualPage.drawText(comercio, {
        x: 135,
        y: 390,
        size: 7,
        font: helveticaFont
      })
      // tarjeta
      actualPage.drawText(data.tarjeta_numero, {
        x: 90,
        y: 380,
        size: 7,
        font: helveticaFont
      })
      // titular
      actualPage.drawText(data.cliente_nombre, {
        x: 45,
        y: 370,
        size: 7,
        font: helveticaFont
      })
      // telefono
      let telefono = '-'
      if (data.cliente_telefono != null && data.cliente_telefono != undefined && data.cliente_telefono.toString().length > 0){
        telefono = data.cliente_telefono.toString()
      }
      actualPage.drawText(telefono, {
        x: 53,
        y: 360,
        size: 7,
        font: helveticaFont
      })
      // codigo autorizacion
      actualPage.drawText(data.codigo_autorizacion, {
        x: 72,
        y: 349,
        size: 7,
        font: helveticaFont
      })
      // transaccion
      actualPage.drawText(data.transaccion_id, {
        x: 61,
        y: 338,
        size: 7,
        font: helveticaFont
      })
      // numero
      actualPage.drawText(data.numero, {
        x: 50,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // lote
      let lote = ''
      if (data.lote != null && data.lote != undefined && data.lote.toString().length > 0) lote = data.lote
      actualPage.drawText(lote, {
        x: 95,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // cuotas
      actualPage.drawText(data.cuotas.toString(), {
        x: 137,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // solicitud de adhesion
      if (data.solicitud_adhesion != null && data.solicitud_adhesion != undefined && data.solicitud_adhesion.toString().length > 0){
        actualPage.drawText('Solicitud Adhesion: ' + data.solicitud_adhesion.toString(), {
          x: 26,
          y: 317,
          size: 7,
          font: helveticaFont
        })
      }
      // total
      actualPage.drawText(convertDecimals(data.importe).toString(), {
        x: 73,
        y: 304,
        size: 10,
        font: helveticaFont
      })
      // original
      if (id == 0){
        actualPage.drawText('ORIGINAL COMERCIO', {
          x: 62,
          y: 240,
          size: 8,
          font: helveticaFont
        })
      }
    }
    let pdfBytes = await pdfDoc.save()
    return {
      resultado: 1,
      msj: 'OK',
      pdf: pdfBytes.buffer
    }
  }catch(error){
    return {
      resultado: 0,
      msj: 'Ocurrio un problema al ejecutar el metodo pdfCobroOnlineTermicaAnulacion: ' + error.message,
      pdf: null
    }
  }
}

async function pdfCobroOnlineA4(data){
  try{
    // obtengo la plantilla
    const existingPdfBytes = await get_pdf('pdfComprobanteElectronicoA4')
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    // empiezo a escribir
    // fecha
    let fecha = ''
    let fechaComp= ''
    if (!isNaN(data.tdate)){
      fecha = moment.unix(parseInt(data.tdate)).format("DD/MM/YYYY")
      fechaComp = moment.unix(parseInt(data.tdate))
    }else{
      fecha = moment(data.tdate.toString()).format("DD/MM/YYYY")
      fechaComp = moment(data.tdate.toString())
    }
    firstPage.drawText(fecha.toString(), {
      x: 50,
      y: 799,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(fecha.toString(), {
      x: 260,
      y: 799,
      size: 7,
      font: helveticaFont,
    })
    // hora
    firstPage.drawText(moment(fechaComp).format("HH:mm"), {
      x: 110,
      y: 799,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(moment(fechaComp).format("HH:mm"), {
      x: 320,
      y: 799,
      size: 7,
      font: helveticaFont,
    })
    // empresa
    firstPage.drawText(data.empresa_nombre, {
      x: 30,
      y: 765,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.empresa_nombre, {
      x: 240,
      y: 765,
      size: 7,
      font: helveticaFont,
    })
    // direccion
    firstPage.drawText(data.empresa_domicilio, {
      x: 55,
      y: 752,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.empresa_domicilio, {
      x: 263,
      y: 752,
      size: 7,
      font: helveticaFont,
    })
    // cuit
    firstPage.drawText(data.empresa_cuit, {
      x: 42,
      y: 736,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.empresa_cuit, {
      x: 250,
      y: 736,
      size: 7,
      font: helveticaFont,
    })
    // comercio
    let comercio = '-'
    if (data.comercio_id != null && data.comercio_id != undefined && data.comercio_id.toString().length > 0){
      comercio = data.comercio_id.toString()
    }
    firstPage.drawText(comercio, {
      x: 54,
      y: 720.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(comercio, {
      x: 262,
      y: 720.5,
      size: 7,
      font: helveticaFont,
    })
    // tarjeta
    firstPage.drawText(data.tarjeta_numero, {
      x: 92,
      y: 705,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.tarjeta_numero, {
      x: 302,
      y: 705,
      size: 7,
      font: helveticaFont,
    })
    // titular
    firstPage.drawText(data.cliente_nombre, {
      x: 45,
      y: 689,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.cliente_nombre, {
      x: 254,
      y: 689,
      size: 7,
      font: helveticaFont,
    })
    // telefono
    let telefono = '-'
    if (data.cliente_telefono != null && data.cliente_telefono != undefined && data.cliente_telefono.toString().length > 0){
      telefono = data.cliente_telefono.toString()
    }
    firstPage.drawText(telefono, {
      x: 52,
      y: 674,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(telefono, {
      x: 260,
      y: 674,
      size: 7,
      font: helveticaFont,
    })
    // codigo autorizacion
    firstPage.drawText(data.codigo_autorizacion, {
      x: 71,
      y: 658,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.codigo_autorizacion, {
      x: 280,
      y: 658,
      size: 7,
      font: helveticaFont,
    })
    // numero
    firstPage.drawText(data.numero, {
      x: 50,
      y: 641.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.numero, {
      x: 260,
      y: 641.5,
      size: 7,
      font: helveticaFont,
    })
    // lote
    let lote = ''
    if (data.lote != null && data.lote != undefined && data.lote.toString().length > 0) lote = data.lote
    firstPage.drawText(lote, {
      x: 105,
      y: 641.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(lote, {
      x: 314,
      y: 641.5,
      size: 7,
      font: helveticaFont,
    })
    // total
    firstPage.drawText(convertDecimals(data.importe).toString(), {
      x: 60,
      y: 625.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(convertDecimals(data.importe).toString(), {
      x: 269,
      y: 625.5,
      size: 7,
      font: helveticaFont,
    })
    // cuotas
    firstPage.drawText(data.cuotas.toString(), {
      x: 113,
      y: 625.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.cuotas.toString(), {
      x: 322,
      y: 625.5,
      size: 7,
      font: helveticaFont,
    })
    // transaccion
    firstPage.drawText(data.transaccion_id.toString(), {
      x: 61,
      y: 608.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.transaccion_id.toString(), {
      x: 272,
      y: 608.5,
      size: 7,
      font: helveticaFont,
    })
    // marca
    firstPage.drawText('Marca: ' + data.marca.toString(), {
      x: 24,
      y: 593,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText('Marca: ' + data.marca.toString(), {
      x: 234,
      y: 593,
      size: 7,
      font: helveticaFont,
    })
    // solicitud de adhesion
    if (data.solicitud_adhesion != null && data.solicitud_adhesion != undefined && data.solicitud_adhesion.toString().length > 0){
      firstPage.drawText('Solicitud Adhesion: ' + data.solicitud_adhesion.toString(), {
        x: 24,
        y: 580,
        size: 7,
        font: helveticaFont,
      })
      firstPage.drawText('Solicitud Adhesion: ' + data.solicitud_adhesion.toString(), {
        x: 234,
        y: 580,
        size: 7,
        font: helveticaFont,
      })
    }
    // respuesta
    const pdfBytes = await pdfDoc.save()
    return {
      resultado: 1,
      msj: 'OK',
      pdf: pdfBytes.buffer
    }
  }catch(error){
    return {
      resultado: 0,
      msj: 'Ocurrio un problema al ejecutar el metodo pdfCobroOnlineA4: ' + error.message,
      pdf: null
    }
  }
}

async function pdfCobroOnlineA4Anulacion(data){
  try{
    // obtengo la plantilla
    const existingPdfBytes = await get_pdf('pdfCompElectronicoAnulacionA4')
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    // empiezo a escribir
    // fecha
    let fecha = ''
    let fechaComp= ''
    if (!isNaN(data.tdate)){
      fecha = moment.unix(parseInt(data.tdate)).format("DD/MM/YYYY")
      fechaComp = moment.unix(parseInt(data.tdate))
    }else{
      fecha = moment(data.tdate.toString()).format("DD/MM/YYYY")
      fechaComp = moment(data.tdate.toString())
    }
    firstPage.drawText(fecha.toString(), {
      x: 50,
      y: 799,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(fecha.toString(), {
      x: 260,
      y: 799,
      size: 7,
      font: helveticaFont,
    })
    // hora
    firstPage.drawText(moment(fechaComp).format("HH:mm"), {
      x: 110,
      y: 799,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(moment(fechaComp).format("HH:mm"), {
      x: 320,
      y: 799,
      size: 7,
      font: helveticaFont,
    })
    // empresa
    firstPage.drawText(data.empresa_nombre, {
      x: 30,
      y: 765,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.empresa_nombre, {
      x: 240,
      y: 765,
      size: 7,
      font: helveticaFont,
    })
    // direccion
    firstPage.drawText(data.empresa_domicilio, {
      x: 55,
      y: 752,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.empresa_domicilio, {
      x: 263,
      y: 752,
      size: 7,
      font: helveticaFont,
    })
    // cuit
    firstPage.drawText(data.empresa_cuit, {
      x: 42,
      y: 736,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.empresa_cuit, {
      x: 250,
      y: 736,
      size: 7,
      font: helveticaFont,
    })
    // comercio
    let comercio = '-'
    if (data.comercio_id != null && data.comercio_id != undefined && data.comercio_id.toString().length > 0){
      comercio = data.comercio_id.toString()
    }
    firstPage.drawText(comercio, {
      x: 54,
      y: 720.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(comercio, {
      x: 262,
      y: 720.5,
      size: 7,
      font: helveticaFont,
    })
    // tarjeta
    firstPage.drawText(data.tarjeta_numero, {
      x: 92,
      y: 705,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.tarjeta_numero, {
      x: 302,
      y: 705,
      size: 7,
      font: helveticaFont,
    })
    // titular
    firstPage.drawText(data.cliente_nombre, {
      x: 45,
      y: 689,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.cliente_nombre, {
      x: 254,
      y: 689,
      size: 7,
      font: helveticaFont,
    })
    // telefono
    let telefono = '-'
    if (data.cliente_telefono != null && data.cliente_telefono != undefined && data.cliente_telefono.toString().length > 0){
      telefono = data.cliente_telefono.toString()
    }
    firstPage.drawText(telefono, {
      x: 52,
      y: 674,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(telefono, {
      x: 260,
      y: 674,
      size: 7,
      font: helveticaFont,
    })
    // codigo autorizacion
    firstPage.drawText(data.codigo_autorizacion, {
      x: 71,
      y: 658,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.codigo_autorizacion, {
      x: 280,
      y: 658,
      size: 7,
      font: helveticaFont,
    })
    // numero
    firstPage.drawText(data.numero, {
      x: 50,
      y: 641.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.numero, {
      x: 260,
      y: 641.5,
      size: 7,
      font: helveticaFont,
    })
    // lote
    let lote = ''
    if (data.lote != null && data.lote != undefined && data.lote.toString().length > 0) lote = data.lote
    firstPage.drawText(lote, {
      x: 105,
      y: 641.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(lote, {
      x: 314,
      y: 641.5,
      size: 7,
      font: helveticaFont,
    })
    // total
    firstPage.drawText(convertDecimals(data.importe).toString(), {
      x: 60,
      y: 625.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(convertDecimals(data.importe).toString(), {
      x: 269,
      y: 625.5,
      size: 7,
      font: helveticaFont,
    })
    // cuotas
    firstPage.drawText(data.cuotas.toString(), {
      x: 113,
      y: 625.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.cuotas.toString(), {
      x: 322,
      y: 625.5,
      size: 7,
      font: helveticaFont,
    })
    // transaccion
    firstPage.drawText(data.transaccion_id.toString(), {
      x: 61,
      y: 608.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.transaccion_id.toString(), {
      x: 272,
      y: 608.5,
      size: 7,
      font: helveticaFont,
    })
    // marca
    firstPage.drawText('Marca: ' + data.marca.toString(), {
      x: 24,
      y: 593,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText('Marca: ' + data.marca.toString(), {
      x: 234,
      y: 593,
      size: 7,
      font: helveticaFont,
    })
    // solicitud de adhesion
    if (data.solicitud_adhesion != null && data.solicitud_adhesion != undefined && data.solicitud_adhesion.toString().length > 0){
      firstPage.drawText('Solicitud Adhesion: ' + data.solicitud_adhesion.toString(), {
        x: 24,
        y: 580,
        size: 7,
        font: helveticaFont,
      })
      firstPage.drawText('Solicitud Adhesion: ' + data.solicitud_adhesion.toString(), {
        x: 234,
        y: 580,
        size: 7,
        font: helveticaFont,
      })
    }
    // respuesta
    const pdfBytes = await pdfDoc.save()
    return {
      resultado: 1,
      msj: 'OK',
      pdf: pdfBytes.buffer
    }
  }catch(error){
    return {
      resultado: 0,
      msj: 'Ocurrio un problema al ejecutar el metodo pdfCobroOnlineA4Anulacion: ' + error.message,
      pdf: null
    }
  }
}

async function pdfCobroOnline(data){
  try{
    // debo obtener la plantilla de la termica o de A4?
    let pdfPeticion = {}
    if (data.impresora_termica == 1){
      // es anulacion o cobro exitoso?
      if (data.estado == 2 || data.estado == 8 || data.estado == 9){
        // cobro exitoso
        pdfPeticion = await pdfCobroOnlineTermica(data)
      }else{
        // anulacion
        pdfPeticion = await pdfCobroOnlineTermicaAnulacion(data)
      }
    }else{
      // es anulacion o cobro exitoso?
      if (data.estado == 2 || data.estado == 8 || data.estado == 9){
        // cobro exitoso
        pdfPeticion = await pdfCobroOnlineA4(data)
      }else{
        // anulacion
        pdfPeticion = await pdfCobroOnlineA4Anulacion(data)
      }
    }
    return pdfPeticion
  }catch(error){
    return {
      resultado: 0,
      msj: 'Ocurrio un problema al ejecutar el metodo pdfCobroOnline: ' + error.message,
      pdf: null
    }
  }
}

async function etiquetasPrecio (data) {
  
  // creo la primer hoja del pdf (por defecto genera una a4)
  let doc = new jsPDF({ putOnlyUsedFonts: false })

  // defino las medidass de la hoja
  const width = 210  // ancho de 21 cm
  const height = 297 // alto de 29,7 cm
  const margin = 10  // margenes de 1 cm

  // obtengo el template de la etiqueta
  const etiquetaTemplate = ArrayBufferToBase64(await get_file('/jarvis_2.0/Cartel de Precios - fondo.png', 'image/png'))

  // agrego la famosa poppins
  doc.addFont(require('./fuentes/Poppins-Bold.ttf'), 'Poppins', 'bold')
  doc.addFont(require('./fuentes/Poppins-Medium.ttf'), 'Poppins', 'normal')

  ////**  FUNCION PARA ARMAR LA ETIQUETA  *////
  function etiqueta (x, y, art) {
    // inserto el template
    doc.addImage(etiquetaTemplate, 'PNG', x, y, 60, 42.5)

    // articulo nombre
    const nombre = salto_linea(art.articulo, 27)
    doc.setFontSize(10)
    doc.setTextColor(0)
    doc.setFont('Poppins', 'normal')
    doc.text(nombre.parte1, x + 30, nombre.parte2 ? y + 12.75 : y + 14.75, null, null, 'center')
    doc.text(nombre.parte2, x + 30, y + 17, null, null, 'center')

    // precio
    doc.setFontSize(14)
    doc.setTextColor(225, 4, 54)
    doc.setFont('Poppins', 'bold')
    doc.text(format_money(art.precio).substring(0, format_money(art.precio).length - 3), x + 30.5, y + 27.5, null, null, 'center')

    // codigo
    doc.setFontSize(9)
    doc.setTextColor(255, 255, 255)
    doc.setFont('Poppins', 'bold')
    doc.text(`CÓDIGO: ${art.codigo}`, x + 30, y + 39.5, null, null, 'center')

  }

  let cantidad_insertada = 0
  let pos_x = 0
  let pos_y = 0

  for (let index = 0; index < data.length; index++) {
    const articulo = data[index]
    
    // agrego tantas etiquetas como stock tenga el articulo
    for (let i = 0; i < articulo.stock; i++) {
      
      // si el modulo de 3 de la cantidad insertada es 0 significa que ya inserto una fila y pasa a la siguiente
      if (cantidad_insertada % 3 == 0) {
        pos_x = margin
        pos_y += 46
      }
      // si todavia sigue en la misma fila solo me corro lateralmente
      else {
        pos_x += 65
      }

      // si el modulo de 18 de la cantidad insertada es 0 significa que es la primer etiqueta de la pagina
      if (cantidad_insertada % 18 == 0) {
        if (cantidad_insertada != 0) {
          doc.addPage()
        }
        pos_x = margin
        pos_y = margin
      }

      // inserto la etiqueta
      cantidad_insertada ++
      etiqueta(pos_x, pos_y, articulo)

    }

  }

  // devuelvo los bytes del pdf generado
  return doc.output('arraybuffer')

}

async function barcodePDF (imagen, nombre, codigo, cantidad) {

  const width = cantidad == 1 ? 50 : 102
  const height = 25

  // creo la primer hoja del pdf (el tamaño depende de la cantidad)
  let doc = new jsPDF({  orientation: 'l', putOnlyUsedFonts: false, format: [width, height] })
  doc.setFont('helvetica', 'bold')

  doc.addImage(imagen, "PNG", 2.5, 7.5, 45, 13)
  doc.setFontSize(8)
  doc.text(codigo, 23, 3.5)
  doc.setFontSize(5)
  doc.text(nombre.substring(0, 42), 2, 5.7)

  if (cantidad == 2) {
    doc.addImage(imagen, "PNG", 55.5, 7.5, 45, 13)
    doc.setFontSize(8)
    doc.text(codigo, 76, 3.5)
    doc.setFontSize(5)
    doc.text(nombre.substring(0, 42), 55, 5.7)
  }

  // devuelvo los bytes del pdf generado
  return doc.output('arraybuffer')

}

async function operacionesCajaTermica (data) {
  const ancho = 80
  let altura = 0

  if (data.impresora_termica == 1) {
    altura = 800
  } else {
    data.detalle.forEach(() => { altura += 10 })
    data.resumen.forEach(() => { altura += 3.5 })
    altura += 100
  }

  // instancio el pdf de tamaño super extra large
  let doc = new jsPDF({ format: [ancho, altura] })

  // seteo la feunte y cantidad de paginas
  doc.addFont(require('./fuentes/Poppins-Medium.ttf'), 'Poppins', 'normal')
  doc.setFont('Poppins', 'normal')
  doc.setFontSize(7)
  doc.setLineWidth(0.1)
  /*
  // LINEA ROJA DELIMITANTE DE MARGENES IMP TERMICA
  doc.setDrawColor('#FF5252')
  doc.line(3, 0, 3, 600)
  doc.line(72, 0, 72, 600)
  doc.setDrawColor('#000000')
  */

  const encabezado = { ...data.encabezado }

  // HOJA DETALLE DE OPERACIONES DE CAJA
  doc.text('Caja : ' + encabezado.caja_numero, 3, 6)
  doc.text(encabezado.sucursal, 35, 10, null, null, 'center')
  doc.text('Fecha : ' + moment(encabezado.fecha).format('DD/MM/YYYY'), 48, 6)
  doc.text('Usuario : ' + encabezado.usuario, 72, 3, null, null, 'right')
  doc.text(encabezado.local, 35, 13, null, null, 'center')

  doc.text('Detalle Efectivo', 35, 18.5, null, null, 'center')
  doc.text('Fecha', 3, 23.5)
  doc.text('Reg. Int.', 14, 23.5)
  doc.text('Tipo Comp.', 25, 23.5)
  doc.text('Descripcion', 41, 23.5)
  doc.text('Importe', 60, 23.5)

  let alto = 0
  for (const detalle of data.detalle) {
    doc.line(3, 24.5 + alto, 72, 24.5 + alto)
    
    doc.setFontSize(6)
    doc.text(moment(detalle.fecha).format('DD/MM/YY'), 3, 27 + alto)
    doc.text(str(detalle.cobranza, ''), 14, 27 + alto)

    // tipo comprobante
    doc.setFontSize(5.5)
    const comprobante = salto_linea(detalle.comprobante, 13)
    doc.text(comprobante.parte1, 25, 27 + alto)
    doc.text(comprobante.parte2, 25, 29.5 + alto)

    // descripcion del tipo de comprobante
    doc.setFontSize(5)
    const descripcion = salto_linea(detalle.descripcion, 15)
    doc.text(descripcion.parte1, 42, 27 + alto)
    const descripcion2 = descripcion.parte2.length > 15 ? salto_linea(descripcion.parte2, 5) : descripcion.parte2
    doc.text(descripcion.parte2.length > 15 ? descripcion2.parte1 : descripcion2, 42, 29 + alto)
    doc.text(descripcion.parte2.length > 15 ? descripcion2.parte2 : '', 42, 31 + alto)

    doc.setFontSize(6)
    doc.text(format_money(detalle.importe), 72, 27 + alto, null, null, 'right')

    alto += 10
  }
  doc.line(3, 29 + alto, 72, 29 + alto)

  doc.setFontSize(7)
  doc.text('Cupones TC : ', 3, 32 + alto)
  doc.text(str(data.total_cupones, ''), 22, 32 + alto)
  doc.text('TOTAL : ', 41, 32 + alto)
  doc.text(format_money(data.total_detalle), 72, 32 + alto, null, null, 'right')

  alto -= 10
  // resumen de pagos
  doc.text('Resumen Caja', 35, 57 + alto, null, null, 'center')
  doc.text('Tipo Comp.', 3, 61.5 + alto)
  doc.text('Efectivo', 60, 61.5 + alto)

  for (const det_mov of data.resumen) {
    doc.line(3, 62.5 + alto, 72, 62.5 + alto)
    doc.text(str(det_mov.comprobante, ''), 3, 65 + alto)
    doc.text(format_money(det_mov.efectivo), 72, 65 + alto, null, null, 'right')
    
    alto += 3.5
  }
  doc.line(3, 65 + alto, 72, 65 + alto)

  doc.text('TOTAL : ', 41, 68.5 + alto)
  doc.text(format_money(data.total_resumen), 72, 68.5 + alto, null, null, 'right')
  
  alto += 5
  doc.text('Firma Responsable :', 5, 80 + alto)
  doc.line(32, 80 + alto, 66, 80 + alto)
  doc.text('Aclaracion :', 15, 88 + alto)
  doc.line(32, 88 + alto, 66, 88 + alto)
  doc.text('Fecha :', 21, 94 + alto)
  doc.line(32, 94 + alto, 66, 94 + alto)

  doc.text('.', 3, 97 + alto)

  // devuelvo los bytes del pdf generado
  return doc.output('arraybuffer')
  
}

async function operacionesCajaA4 (data) {
  // defino las medidas de la hoja
  const width = 210  // ancho de 21 cm
  const height = 297 // alto de 29,7 cm
  const margins = 15  // margenes de 1,5 cm

  const limite_inferior = height - margins
  const gris_header = '#e1e1e1' //dcdcdc

  const encabezado = { ...data.encabezado }

  // creo la primer hoja del pdf (por defecto genera una a4)
  let doc = new jsPDF({ putOnlyUsedFonts: false })

  // seteo la feunte
  doc.addFont(require('./fuentes/Poppins-Medium.ttf'), 'Poppins', 'normal')

  /**
   *  Nota: cada row de las tablas que tengan 1 de padding, miden exactamente 5.25 de alto
   *        con este valor se calcula el alto total de la tabla en base a la longitud + 1 del array
   *        se le suma 1 por los encabezados
   */
  const alto_row = 5.25

  // funcion para setear el encabezado
  function setEncabezado () {
    // recuadro rectangular
    doc.setLineWidth(0.1)
    doc.line(margins, margins, width - margins, margins)
    doc.line(margins, margins + 20, width - margins, margins + 20)
    doc.line(margins, margins, margins, margins + 20)
    doc.line(width - margins, margins, width - margins, margins + 20)
    // info
    doc.setFontSize(10)
    doc.setFont('Poppins', 'normal')
    doc.text('Caja Nº : ' + encabezado.caja_numero, margins + 3, margins + 15)
    doc.text(encabezado.local, width / 2, margins + 15, null, null, 'center')
    doc.text('Fecha : ' + moment(encabezado.fecha).format('DD/MM/YYYY'), width - margins - 3, margins + 15, null, null, 'right')
    doc.text('Usuario : ' + encabezado.usuario, width - margins - 3, margins + 7, null, null, 'right')
  }
  setEncabezado()

  ////**  HEADERS PARA LAS TABLAS  *////
  const header_detalle = [
    { id: 'fecha', name: 'fecha', prompt: 'Fecha', width: 25, align: 'left' },
    { id: 'cobranza', name: 'cobranza', prompt: 'Reg. Int.', width: 20, align: 'left' },
    { id: 'comprobante', name: 'comprobante', prompt: 'Tipo Comp.', width: 70, align: 'left' },
    { id: 'descripcion', name: 'descripcion', prompt: 'Descripcion', width: 90, align: 'left' },
    { id: 'importe', name: 'importe', prompt: 'Importe', width: 35, align: 'right' }
  ]
  const header_resumen = [
    { id: 'comprobante', name: 'comprobante', prompt: 'Tipo Comp.', width: 205, align: 'left' },
    { id: 'efectivo', name: 'efectivo', prompt: 'Efectivo', width: 35, align: 'right' }
  ]

  let y_final_elemento = 0
  function funcionMaestra (posicion_inicial_tabla, array, headers) {
    // inicializacion de variables
    y_final_elemento = 0
    let y_inicial_tabla = posicion_inicial_tabla
    let total_rowsXpagina = parseInt((limite_inferior - y_inicial_tabla) / alto_row)
    let subarray = array

    // si el total de rows x pagina es negativo o 0 significa que la tabla no entra en lo que queda de espacio en la hoja
    if (total_rowsXpagina <= 0) {
      total_rowsXpagina = 0
      // si la longitud del array es 0 entonces debo isnertar la cabecera de la tabla ya que en el siguiente "if (y_final_elemento === 0)" no insertaria nada
      if (array.length == 0) {
        doc.addPage()
        setEncabezado()
        // INSERTO LA TABLA
        doc.table(margins, margins + 25, subarray, headers, { autoSize: false, fontSize: 8, padding: 1, headerBackgroundColor: gris_header })
        y_final_elemento = alto_row + margins + 25
      }
    }
    // si el total de rows es mayor a 0 significa que la tabla entera o parte de ella entra en el espacio que queda
    else {
      // VERIFICO SI EL TOTAL DE ROWS X PAGINA ES MENOR QUE LA LONGITUD TOAL DEL ARRAY + 1 (por el encabezado)
      if (total_rowsXpagina < array.length + 1) {
        /**
         *  Si el total de rows x pagina ex menor a la longitud + 1 significa que la tabla no entra en lo que queda
         *  de espacio en la pagina, por lo tanto debo recortar el array
         */
        subarray = array.slice(0, total_rowsXpagina - 1)
      }
      // caso contrario indico el alto final de la tabla
      else {
        y_final_elemento = ((array.length + 1) * alto_row) + y_inicial_tabla
      }

      // INSERTO LA TABLA
      doc.table(margins, y_inicial_tabla, subarray, headers, { autoSize: false, fontSize: 8, padding: 1, headerBackgroundColor: gris_header })
    }

    // SI EL ALTO FINAL DE LA TABLA ES 0 SIGNIFICA QUE LA TABLA NO ENTRÓ (parcial o totalmente) EN LO QUE QUEDABA DE PAGINA Y SE DEBE HACER UN SALTO
    if (y_final_elemento === 0) {
      
      // obtengo el resto de elementos del array que no se insertaron
      subarray = array.slice(total_rowsXpagina, array.length)
      // calculo nuevamente la cantidad de filas que puedo inserar por hoja
      total_rowsXpagina = parseInt((limite_inferior - margins + 25) / alto_row)
      // separo el subarray en grupos que contengan la longitud maxima que permite insertar la tabla en 1 hoja
      const subarray_stack = divideArray(subarray, total_rowsXpagina - 1)

      // recorro los grupos de arrays para insertar las tablas en las paginas
      for (let index = 0; index < subarray_stack.length; index++) {
        const stack = subarray_stack[index]
        doc.addPage()
        // isnerta el encabezado
        setEncabezado()
        // inserta la tabla
        doc.table(margins, margins + 25, stack, headers, { autoSize: false, fontSize: 8, padding: 1, headerBackgroundColor: gris_header })
        // Si se esta insertando la ultima tabla hay que obtener el alto final de la misma para insertar el siguiente elemento a la misma altura
        if (index == subarray_stack.length - 1) {
          y_final_elemento = ((stack.length + 1) * alto_row) + margins + 25
        }
      }
    }
  }

  //**  TABLA DETALLE EFECTIVO  *//
  doc.setFontSize(12)
  doc.text('Detalle Efectivo', width / 2, margins + 30, null, null, 'center')
  funcionMaestra(
    margins + 35,
    data.detalle.map(detalle => {
      detalle.fecha = moment(detalle.fecha).format('DD/MM/YYYY')
      detalle.importe = format_money(detalle.importe)
      return detalle
    }),
    header_detalle
  )
  // VERIFICAMOS QUE EL ALTO DEL SIGUIENTE ELEMENTO ENTRE EN LO QUE QUEDA DE PAGINA sino hacemos un salto de pagina
  function verificarSaltoPagina (altura) {
    if (y_final_elemento + altura > limite_inferior) {
      doc.addPage()
      setEncabezado()
      y_final_elemento = margins + 25
    }
  }
  verificarSaltoPagina(10)
  // inserto el total del detalle efectivo
  doc.setFontSize(11)
  doc.setFont('Poppins', 'normal')
  doc.text('TOTAL : ', 120, y_final_elemento + 7)
  doc.text(format_money(data.total_detalle), width - margins - 1.5, y_final_elemento + 7, null, null, 'right')

  //**  TABLA RESUMEN CAJA  *//
  verificarSaltoPagina(20)
  doc.setFontSize(12)
  doc.text('Resumen Caja', width / 2, y_final_elemento + 20, null, null, 'center')
  funcionMaestra(
    y_final_elemento + 25,
    data.resumen.map(res => {
      res.efectivo = format_money(res.efectivo)
      return res
    }),
    header_resumen
  )
  // inserto el total del resumen
  verificarSaltoPagina(10)
  doc.setFontSize(11)
  doc.setFont('Poppins', 'normal')
  doc.text('TOTAL : ', 120, y_final_elemento + 7)
  doc.text(format_money(data.total_resumen), width - margins - 1.5, y_final_elemento + 7, null, null, 'right')

  //**  FIRMAS  *//
  verificarSaltoPagina(45)
  doc.setFontSize(10)
  doc.text('Firma Responsable :', margins, y_final_elemento + 25)
  doc.text('Aclaracion :', margins, y_final_elemento + 35)
  doc.text('Fecha :', margins, y_final_elemento + 45)

  // agregamos los numeros de pagina al final de cada pagina
  for (let index = 1; index <= doc.getNumberOfPages(); index++) {
    doc.setPage(index)
    doc.text(`Hoja ${index} de ${doc.getNumberOfPages()}`, margins + 3, margins + 7)
  }

  // devuelvo los bytes del pdf generado
  return doc.output('arraybuffer')

}

async function operacionesAceptacionA4 (data) {
  // defino las medidas de la hoja
  const width = 210  // ancho de 21 cm
  const height = 297 // alto de 29,7 cm
  const margin_y = 15  // margenes de 1,5 cm
  const margin_x = 10  // margenes de 1 cm

  const limite_inferior = height - margin_y
  const gris_header = '#e1e1e1' //dcdcdc

  const encabezado = { ...data.encabezado }

  // creo la primer hoja del pdf (por defecto genera una a4)
  let doc = new jsPDF({ putOnlyUsedFonts: false })

  // seteo la feunte
  doc.addFont(require('./fuentes/Poppins-Medium.ttf'), 'Poppins', 'normal')

  /**
   *  Nota: cada row de las tablas que tengan 1 de padding, miden exactamente 5.25 de alto
   *        con este valor se calcula el alto total de la tabla en base a la longitud + 1 del array
   *        se le suma 1 por los encabezados
   */
  const alto_row = 5.25

  // funcion para setear el encabezado
  function setEncabezado () {
    // recuadro rectangular
    doc.setLineWidth(0.1)
    doc.line(margin_x, margin_y, width - margin_x, margin_y)
    doc.line(margin_x, margin_y + 20, width - margin_x, margin_y + 20)
    doc.line(margin_x, margin_y, margin_x, margin_y + 20)
    doc.line(width - margin_x, margin_y, width - margin_x, margin_y + 20)
    // info
    doc.setFontSize(8)
    doc.setFont('Poppins', 'normal')
    doc.text('Recibo Aceptación Nº : ' + encabezado.aceptacion, margin_x + 3, margin_y + 11)
    doc.text(encabezado.descripcion, width / 2, margin_y + 11, null, null, 'center')
    doc.text('Caja Nº : ' + encabezado.caja_numero, width - margin_x - 3, margin_y + 11, null, null, 'right')
    doc.text('Fecha Apertura : ' + encabezado.fecha_apertura, margin_x + 3, margin_y + 17)
    doc.text('Estado : ' + encabezado.estado, width / 2, margin_y + 17, null, null, 'center')
    doc.text('Turno : ' + encabezado.turno, width - margin_x - 3, margin_y + 17, null, null, 'right')
  }
  setEncabezado()

  let y_final_elemento = 0
  function funcionMaestra (posicion_inicial_tabla, array, headers) {
    // inicializacion de variables
    y_final_elemento = 0
    let y_inicial_tabla = posicion_inicial_tabla
    let total_rowsXpagina = parseInt((limite_inferior - y_inicial_tabla) / alto_row)
    let subarray = array

    // si el total de rows x pagina es negativo o 0 significa que la tabla no entra en lo que queda de espacio en la hoja
    if (total_rowsXpagina <= 0) {
      total_rowsXpagina = 0
      // si la longitud del array es 0 entonces debo isnertar la cabecera de la tabla ya que en el siguiente "if (y_final_elemento === 0)" no insertaria nada
      if (array.length == 0) {
        doc.addPage()
        setEncabezado()
        // INSERTO LA TABLA
        doc.table(margin_x, margin_y + 25, subarray, headers, { autoSize: false, fontSize: 8, padding: 1, headerBackgroundColor: gris_header })
        y_final_elemento = alto_row + margin_y + 25
      }
    }
    // si el total de rows es mayor a 0 significa que la tabla entera o parte de ella entra en el espacio que queda
    else {
      // VERIFICO SI EL TOTAL DE ROWS X PAGINA ES MENOR QUE LA LONGITUD TOAL DEL ARRAY + 1 (por el encabezado)
      if (total_rowsXpagina < array.length + 1) {
        /**
         *  Si el total de rows x pagina ex menor a la longitud + 1 significa que la tabla no entra en lo que queda
         *  de espacio en la pagina, por lo tanto debo recortar el array
         */
        subarray = array.slice(0, total_rowsXpagina - 1)
      }
      // caso contrario indico el alto final de la tabla
      else {
        y_final_elemento = ((array.length + 1) * alto_row) + y_inicial_tabla
      }

      // INSERTO LA TABLA
      doc.table(margin_x, y_inicial_tabla, subarray, headers, { autoSize: false, fontSize: 8, padding: 1, headerBackgroundColor: gris_header })
    }

    // SI EL ALTO FINAL DE LA TABLA ES 0 SIGNIFICA QUE LA TABLA NO ENTRÓ (parcial o totalmente) EN LO QUE QUEDABA DE PAGINA Y SE DEBE HACER UN SALTO
    if (y_final_elemento === 0) {
      
      // obtengo el resto de elementos del array que no se insertaron
      subarray = array.slice(total_rowsXpagina, array.length)
      // calculo nuevamente la cantidad de filas que puedo inserar por hoja
      total_rowsXpagina = parseInt((limite_inferior - margin_y + 25) / alto_row)
      // separo el subarray en grupos que contengan la longitud maxima que permite insertar la tabla en 1 hoja
      const subarray_stack = divideArray(subarray, total_rowsXpagina - 1)

      // recorro los grupos de arrays para insertar las tablas en las paginas
      for (let index = 0; index < subarray_stack.length; index++) {
        const stack = subarray_stack[index]
        doc.addPage()
        // isnerta el encabezado
        setEncabezado()
        // inserta la tabla
        doc.table(margin_x, margin_y + 25, stack, headers, { autoSize: false, fontSize: 8, padding: 1, headerBackgroundColor: gris_header })
        // Si se esta insertando la ultima tabla hay que obtener el alto final de la misma para insertar el siguiente elemento a la misma altura
        if (index == subarray_stack.length - 1) {
          y_final_elemento = ((stack.length + 1) * alto_row) + margin_y + 25
        }
      }
    }
  }

  //**  TABLA  *//
  doc.setFontSize(12)
  doc.text('Recepción Operaciones de Caja', width / 2, margin_y + 30, null, null, 'center')
  funcionMaestra(
    margin_y + 35,
    data.detalle.map(detalle => {
      detalle.descripcion = `${detalle.descripcion.substring(0, 45)}${detalle.descripcion.length > 45 ? '...' : ''}`
      detalle.efectivo = format_money(detalle.efectivo)
      detalle.importe = format_money(detalle.importe)
      detalle.otros = format_money(detalle.otros)
      return detalle
    }),
    [
      { id: 'fecha', name: 'fecha', prompt: 'Fecha', width: 25, align: 'left' },
      { id: 'numero', name: 'numero', prompt: 'Numero', width: 20, align: 'left' },
      { id: 'abreviatura', name: 'abreviatura', prompt: 'Abreviatura', width: 35, align: 'left' },
      { id: 'descripcion', name: 'descripcion', prompt: 'Descripción', width: 83, align: 'left' },
      { id: 'efectivo', name: 'efectivo', prompt: 'Efectivo', width: 30, align: 'right' },
      { id: 'otros', name: 'otros', prompt: 'Otros', width: 30, align: 'right' },
      { id: 'importe', name: 'importe', prompt: 'Importe', width: 30, align: 'right' }
    ]
  )
  // totales
  doc.table(margin_x, y_final_elemento, [],
    [
      { id: 'descripcion', name: 'descripcion', prompt: ' ', width: 163, align: 'left' },
      { id: 'efectivo', name: 'efectivo', prompt: format_money(data.total_efectivo), width: 30, align: 'right' },
      { id: 'otros', name: 'otros', prompt: format_money(data.total_otros), width: 30, align: 'right' },
      { id: 'importe', name: 'importe', prompt: format_money(data.total_importe), width: 30, align: 'right' }
    ],
    { autoSize: false, fontSize: 8, padding: 1, headerBackgroundColor: gris_header }
  )
  
  // VERIFICAMOS QUE EL ALTO DEL SIGUIENTE ELEMENTO ENTRE EN LO QUE QUEDA DE PAGINA sino hacemos un salto de pagina
  function verificarSaltoPagina (altura) {
    if (y_final_elemento + altura > limite_inferior) {
      doc.addPage()
      setEncabezado()
      y_final_elemento = margin_y + 25
    }
  }

  // detalle en palabras
  verificarSaltoPagina(30)
  doc.setFontSize(10)
  doc.setFont('Poppins', 'normal')
  const monto = salto_linea(`Recibí Pesos : ${NumerosALetras(data.total_importe)} (${format_money(data.total_importe)}) en Concepto de Cobranzas Varias.`, 100)
  doc.text(monto.parte1, margin_x, y_final_elemento + 15)
  doc.text(monto.parte2, margin_x, y_final_elemento + 20)

  //**  FIRMA  *//
  verificarSaltoPagina(60)
  doc.setLineWidth(0.5)
  doc.line(margin_x + 120, y_final_elemento + 50, width - margin_x - 20, y_final_elemento + 50)
  doc.text('Firma', margin_x + 140, y_final_elemento + 55)

  // agregamos los numeros de pagina al final de cada pagina
  doc.setFontSize(8)
  for (let index = 1; index <= doc.getNumberOfPages(); index++) {
    doc.setPage(index)
    doc.text(`Hoja ${index} de ${doc.getNumberOfPages()}`, margin_x + 3, margin_y + 5)
  }

  // devuelvo los bytes del pdf generado
  return doc.output('arraybuffer')

}

async function facturaTermica (data) {
  const ancho = 80
  const altura = 450

  // instancio el pdf de tamaño large
  let doc = new jsPDF({ format: [ancho, altura] })

  // importo y seteo la feunte
  doc.addFont(require('./fuentes/Poppins-Bold.ttf'), 'Poppins', 'bold')
  doc.addFont(require('./fuentes/Poppins-Medium.ttf'), 'Poppins', 'regular')
  doc.setFont('Poppins', 'bold')
  doc.setFontSize(7)

  const factura = { ...data.encabezado }

  // verifico si la imagen del logo es png o jpg
  const extension = factura.imagen_logo.substring(factura.imagen_logo.lastIndexOf('.'), factura.imagen_logo.size).toLowerCase()
  if (extension == '.png') {
    const logoEmpresa = ArrayBufferToBase64(await get_file(factura.imagen_logo, 'image/png'))
    doc.addImage(logoEmpresa, "PNG", 20, 0, 40, 18, null, null, 'center')
  } else {
    const logoEmpresa = ArrayBufferToBase64(await get_file(factura.imagen_logo, 'image/jpeg'))
    doc.addImage(logoEmpresa, "JPEG", 20, 0, 40, 18, null, null, 'center')
  }

  // nombres de los encabezados
  doc.text('CUIT Nº:', 3, 24)
  doc.text('ING. BRUTOS:', 3, 28)
  doc.text('DOMICILIO:', 3, 32)
  doc.text('INICIO ACTIVIDADES:', 3, 36)
  doc.text('CAT. IVA:', 3, 40)

  if (factura.comprobante_tipo == 'FACTURA') {
    doc.text(`FACTURA "${factura.tipo_factura}"`, 40, 46, null, null, 'center')
  } else {
    doc.text(`NOTA DE CREDITO "${factura.tipo_factura}"`, 40, 46, null, null, 'center')
  }
  doc.text('Nº:', 3, 50)
  doc.text('VENDEDOR:', 3, 54)

  doc.text('CLIENTE:', 3, 60)
  doc.text('CUIT/DNI:', 3, 64)
  doc.text('DOMICILIO:', 3, 68)
  doc.text('IVA:', 3, 72)

  doc.text('CANT./PRECIO UNIT.', 3, 78)
  doc.text('IMPORTE', 72, 78, null, null, 'right')
  doc.text('DESCRIPCION', 3, 81)

  // lineas
  doc.setLineWidth(0.5)
  doc.line(3, 42, 72, 42)
  doc.setLineWidth(0.2)
  doc.line(3, 56, 72, 56)
  doc.setLineWidth(0.5)
  doc.line(3, 74, 72, 74)

  // datos de los enbabezdos
  doc.setFont('Poppins', 'regular')
  doc.text(factura.nombre_empresa, 3, 20)
  doc.text(factura.cuit_empresa, 14, 24)
  doc.text(factura.ingresos_brutos, 20, 28)
  doc.text(`${factura.sucursal} - ${factura.domicilio_local}`, 18, 32)
  doc.text(factura.inicio_actividades, 30, 36)
  doc.text(factura.cat_iva_empresa, 16, 40)

  doc.text(factura.emynum, 8, 50)
  doc.text(`${factura.fecha} ${factura.hora_solicitud}`, 72, 50, null, null, 'right')
  doc.text(factura.vendedor, 18, 54)

  doc.text(str(factura.cliente, '-'), 14.5, 60)
  doc.text(str(factura.cuit, '-'), 16, 64)
  doc.text(str(factura.cli_domicilio, '-'), 18, 68)
  doc.text(str(factura.condiva, '-'), 9, 72)

  /***   DETALLES   ***/

  let y_final = 81
  for (const detalle of data.detalle) {
    // ES COMBO
    if (detalle.combo) {
      // obtengo el precio dependieno del tipo de factura
      let precio = factura.tipo_factura == 'B' ? detalle.importeitem : detalle.neto

      doc.setFont('Poppins', 'regular')
      doc.text(`${detalle.cantidad},000/${precio.toFixed(2).replace('.', ',')}`, 3, y_final + 4)
      doc.text(`$ ${precio.toFixed(2).replace('.', ',')}`, 72, y_final + 4, null, null, 'right')

      doc.setFont('Poppins', 'bold')
      doc.text(detalle.descripcion, 3, y_final + 7)

      doc.setFont('Poppins', 'regular')
      y_final += 7

      for (const combo of data.items_combo) {
        const descripcion = split_varchar(`${combo.detalle}${combo.series ? ` - ${combo.series}` : '' }`, 45)
        for (const linea of descripcion) {
          doc.text(linea, 3, y_final + 3)
          y_final += 3
        }
      }

    }
    // NO ES COMBO
    else {
      // obtengo el precio y el total dependieno del tipo de factura
      let precio = factura.tipo_factura == 'B' ? detalle.itempreciounitario : detalle.neto / detalle.cantidad
      let total = factura.tipo_factura == 'B' ? detalle.importeitem : detalle.neto

      doc.setFont('Poppins', 'regular')
      doc.text(`${detalle.cantidad},000/${precio.toFixed(2).replace('.', ',')}`, 3, y_final + 4)
      doc.text(`$ ${total.toFixed(2).replace('.', ',')}`, 72, y_final + 4, null, null, 'right')

      doc.setFont('Poppins', 'bold')
      y_final += 4

      const descripcion = split_varchar(`${detalle.articulo_codigo} - ${detalle.descripcion}`, 45)
      for (const linea of descripcion) {
        doc.text(linea, 3, y_final + 3)
        y_final += 3
      }
    }
  }

  if (factura.tipo_factura == 'A') {
    doc.setFont('Poppins', 'bold')
    doc.text('Subtotal     Desc.           Neto              IVA 21%             IVA 10,5%', 3, y_final + 4)
    doc.text(`$${factura.neto.toFixed(2).replace('.', ',')}`, 3, y_final + 8)
    doc.text('$0', 20, y_final + 8)
    doc.text(`$${factura.neto.toFixed(2).replace('.', ',')}`, 26, y_final + 8)
    doc.text(`$${factura.iva21.toFixed(2).replace('.', ',')}`, 42, y_final + 8)
    doc.text(`$${factura.iva105.toFixed(2).replace('.', ',')}`, 60, y_final + 8)
    y_final += 8
  }

  // TOTAL
  y_final += 2
  doc.setFont('Poppins', 'bold')
  doc.setFontSize(9)
  doc.text('TOTAL:', 3, y_final + 4)
  doc.text(format_money(factura.total), 72, y_final + 4, null, null, 'right')

  // CAE
  doc.setFont('Poppins', 'regular')
  doc.setFontSize(7)
  doc.text(`CAE: ${factura.cae}`, 3, y_final + 8)
  doc.text(`Vencimiento: ${factura.fecha_venc_cae}`, 3, y_final + 11)

  // QR
  const qr = await QRCode.toDataURL(data.qr)
  doc.addImage(qr, "PNG", 20, y_final + 12, 40, 40, null, null, 'center')
  doc.text('GRACIAS POR SU COMPRA', 40, y_final + 54, null, null, 'center')

  // devuelvo los bytes del pdf generado
  return doc.output('arraybuffer')
  
}

async function facturaA4 (data) {
  // defino las medidas de la hoja
  const width = 210   // ancho de 21 cm
  const height = 297  // alto de 29,7 cm
  const margin_y = 10 // margenes de 1 cm
  const margin_x = 5  // margenes de 0,5 cm

  const limite_inferior = height - margin_y
  const factura = { ...data.encabezado }
  let y_final = 0

  // creo la primer hoja del pdf (por defecto genera una a4)
  let doc = new jsPDF({ putOnlyUsedFonts: false })

  // importo y seteo la feunte
  doc.addFont(require('./fuentes/Poppins-Bold.ttf'), 'Poppins', 'bold')
  doc.addFont(require('./fuentes/Poppins-Medium.ttf'), 'Poppins', 'medium')
  doc.addFont(require('./fuentes/Poppins-Regular.ttf'), 'Poppins', 'regular')

  // obtengo la marca de agua
  let nombreAgua = factura.codigo_empresa == 5 ? 'RADA' : 'RFB'
  if (factura.mayorista == 1) nombreAgua = 'LITECH'
  const marcaAgua = ArrayBufferToBase64(await get_file(`/empresas/agua${nombreAgua}.png`, 'image/png'))

  // funcion para setear el encabezado
  function setEncabezado () {
    // marca de wather
    doc.addImage(marcaAgua, "PNG", 0, 0, width, height)

    // recuadro rectangular
    doc.setLineWidth(0.1)
    doc.setDrawColor('#000000')
    doc.line(margin_x, margin_y, width - margin_x, margin_y)
    doc.line(margin_x, margin_y + 50, width - margin_x, margin_y + 50)
    doc.line(margin_x, margin_y, margin_x, margin_y + 50)
    doc.line(width - margin_x, margin_y, width - margin_x, margin_y + 50)
    // recuadro para la letra
    doc.line(width / 2, margin_y + 25, width / 2, margin_y + 50)
    doc.line((width / 2) - 12.5, margin_y + 25, (width / 2) + 12.5, margin_y + 25)
    doc.line((width / 2) - 12.5, margin_y, (width / 2) - 12.5, margin_y + 25)
    doc.line((width / 2) + 12.5, margin_y, (width / 2) + 12.5, margin_y + 25)

    // letra
    doc.setFontSize(48)
    doc.setFont('Poppins', 'bold')
    doc.text(factura.tipo_factura, width / 2, margin_y + 18, null, null, 'center')

    // info
    doc.setFontSize(12)
    doc.setFont('Poppins', 'medium')
    doc.text(factura.nombre_empresa, margin_x + 3, margin_y + 27)
    doc.setFontSize(10)
    doc.text(`Cat. Iva: ${factura.cat_iva_empresa}`, margin_x + 3, margin_y + 32)
    doc.text(`Inicio de Actividades: ${factura.inicio_actividades}`, margin_x + 3, margin_y + 37)
    if (factura.mayorista != 1) doc.text(`Domicilio: ${factura.sucursal} - ${factura.domicilio_local}`, margin_x + 3, margin_y + 42)
    doc.text(factura.vendedor ? `Vendedor: ${factura.vendedor}` : '', margin_x + 3, margin_y + (factura.mayorista == 1 ? 42 : 47))

    doc.setFontSize(20)
    doc.setFont('Poppins', 'bold')
    doc.text(factura.comprobante_tipo, margin_x + 130, margin_y + 15)
    doc.setFontSize(12)
    doc.setFont('Poppins', 'medium')
    doc.text(`Nº ${factura.emynum}`, margin_x + 130, margin_y + 21)
    doc.setFontSize(10)
    doc.text(`Fecha: ${factura.fecha}`, margin_x + 130, margin_y + 27)
    doc.text(`CUIT: ${factura.cuit_empresa}`, margin_x + 130, margin_y + 32)
    doc.text(`Ing. Brutos: ${factura.ingresos_brutos}`, margin_x + 130, margin_y + 37)

    // datos del cliente
    doc.text(`Cliente: ${factura.cliente}`, margin_x, margin_y + 57)
    doc.text(`Domicilio: ${factura.cli_domicilio}`, margin_x, margin_y + 62)
    doc.text(`Iva: ${factura.condiva}`, margin_x, margin_y + 67)
    doc.text(`CUIT: ${factura.cuit}`, width - (margin_x * 2), margin_y + 57, null, null, 'right')
    doc.text('Remito: -', width - (margin_x * 2), margin_y + 62, null, null, 'right')
    
    // encabezado del detalle
    doc.line(margin_x, margin_y + 70, width - margin_x, margin_y + 70)
    doc.setFont('Poppins', 'bold')
    doc.text('Cant.', margin_x + 1, margin_y + 75)
    doc.text('Código', margin_x + 20, margin_y + 75)
    doc.text('Descripción', margin_x + 40, margin_y + 75)
    doc.text('Precio Unit.', margin_x + 150, margin_y + 75)
    doc.text('Importe', width - margin_x - 1, margin_y + 75, null, null, 'right')

    y_final = margin_y + 78
  }
  setEncabezado()

  function saltoPagina (alto) {
    if (y_final + alto > limite_inferior) {
      doc.addPage()
      setEncabezado()
      y_final = margin_y + 78
    }
  }

  for (const detalle of data.detalle) {
    // controlo si hace falta un salto de pagina
    saltoPagina(15)

    doc.setDrawColor('#757575')
    doc.line(margin_x, y_final, width - margin_x, y_final)

    doc.setFontSize(8)
    doc.setFont('Poppins', 'regular')
    doc.text(`${detalle.cantidad}`, margin_x + 5, y_final + 4.5)
    doc.text(`${detalle.articulo_codigo ? detalle.articulo_codigo : ''}`, margin_x + 20, y_final + 4.5)

    let precio = detalle.combo == 1 ? detalle.importeitem : detalle.itempreciounitario
    doc.text(`$ ${precio.toFixed(2).replace('.', ',')}`, width - margin_x - 32, y_final + 4.5, null, null, 'right')
    doc.text(`$ ${detalle.importeitem.toFixed(2).replace('.', ',')}`, width - margin_x - 1, y_final + 4.5, null, null, 'right')

    if (detalle.combo == 1) {
      doc.text(detalle.descripcion, margin_x + 40, y_final + 4.5)
      y_final += 5.5
      for (const combo of data.items_combo) {
        const descripcion = split_varchar(`${combo.detalle}${combo.series ? `${combo.series}` : '' }`, 70)
        for (const linea of descripcion) {
          saltoPagina(5)
          doc.text(linea, margin_x + 40, y_final + 4)
          y_final += 4
        }

        y_final += 0.5
      }
    } else {
      const descripcion = split_varchar(detalle.descripcion, 70)
      for (const linea of descripcion) {
        saltoPagina(5)
        doc.text(linea, margin_x + 40, y_final + 4)
        y_final += 4
      }

      y_final += 2.5
    }
  }

  // verifico si el footer entra en lo que quedo de pagina
  saltoPagina(factura.tipo_factura == 'A' ? 85 : 55)
  
  // si es factura A agrego los totales
  if (factura.tipo_factura == 'A') {
    doc.setFontSize(10)
    doc.setDrawColor('#000000')
    doc.line(margin_x, margin_y + 216, width - margin_x, margin_y + 216)
    doc.setFont('Poppins', 'bold')
    doc.text('Subtotal', margin_x + 1, margin_y + 221)
    doc.text('Descuento', margin_x + 35, margin_y + 221)
    doc.text('Neto', margin_x + 70, margin_y + 221)
    doc.text('IVA 21%', margin_x + 110, margin_y + 221)
    doc.text('IVA 10,5%', margin_x + 150, margin_y + 221)
    doc.text('Total', width - margin_x - 1, margin_y + 221, null, null, 'right')

    doc.setDrawColor('#757575')
    doc.line(margin_x, margin_y + 224, width - margin_x, margin_y + 224)
    doc.setFont('Poppins', 'bold')
    doc.text(`$${factura.neto.toFixed(2).replace('.', ',')}`, margin_x + 1, margin_y + 229)
    doc.text('$0', margin_x + 35, margin_y + 229)
    doc.text(`$${factura.neto.toFixed(2).replace('.', ',')}`, margin_x + 70, margin_y + 229)
    doc.text(`$${factura.iva21.toFixed(2).replace('.', ',')}`, margin_x + 110, margin_y + 229)
    doc.text(`$${factura.iva105.toFixed(2).replace('.', ',')}`, margin_x + 150, margin_y + 229)
    doc.text(`$${factura.total.toFixed(2).replace('.', ',')}`, width - margin_x - 1, margin_y + 229, null, null, 'right')
  }

  // total en letras
  doc.setFontSize(12)
  doc.setFont('Poppins', 'medium')
  doc.text(`Son: ${NumerosALetras(factura.total)}`, margin_x + 1, 248)

  // QR e imagen afip
  const qr = await QRCode.toDataURL(data.qr)
  const afipImg = ArrayBufferToBase64(await get_file('/empresas/afiplogo.png', 'image/png'))
  doc.addImage(qr, "PNG", margin_x, 250, 40, 40, null, null, 'center')
  doc.addImage(afipImg, "PNG", margin_x + 40, 264, 119, 35)

  // total y cae
  doc.setFontSize(18)
  doc.setFont('Poppins', 'bold')
  doc.text(`Total: ${format_money(factura.total)}`, 135, 260)
  doc.setFontSize(12)
  doc.setFont('Poppins', 'medium')
  doc.text('GRACIAS POR SU COMPRA', 135, 267)
  doc.text(`CAE: ${factura.cae}`, 135, 272)
  doc.text(`Vencimiento: ${factura.fecha_venc_cae}`, 135, 277)

  // solo si tiene + de 1 hoja agrego la numeracion
  if (doc.getNumberOfPages() > 1) {
    doc.setFontSize(10)
    for (let index = 1; index <= doc.getNumberOfPages(); index++) {
      doc.setPage(index)
      doc.text(`Hoja ${index} de ${doc.getNumberOfPages()}`, margin_x + 130, margin_y + 45)
    }
  }

  // devuelvo los bytes del pdf generado
  return doc.output('arraybuffer')
}

async function reciboLitechA4(data){
  try {
    // defino las medidas de la hoja
    const width = 210   // ancho de 21 cm
    const height = 297  // alto de 29,7 cm
    const margin_y = 10 // margenes de 1 cm
    const margin_x = 5  // margenes de 0,5 cm

    const limite_inferior = height - margin_y
    let factura = data.encabezado

    // creo la primer hoja del pdf (por defecto genera una a4)
    let doc = new jsPDF({ putOnlyUsedFonts: false })

    // importo y seteo la feunte
    doc.addFont(require('./fuentes/Poppins-Bold.ttf'), 'Poppins', 'bold')
    doc.addFont(require('./fuentes/Poppins-Medium.ttf'), 'Poppins', 'medium')
    doc.addFont(require('./fuentes/Poppins-Regular.ttf'), 'Poppins', 'regular')

    // obtengo la marca de agua
    let  nombreAgua = 'LITECH'
    const marcaAgua = ArrayBufferToBase64(await get_file(`/empresas/agua${nombreAgua}.png`, 'image/png'))

    // funcion para setear el encabezado
    function setEncabezado () {
      // marca de wather
      doc.addImage(marcaAgua, "PNG", 0, 0, width, height)

      // recuadro rectangular
      doc.setLineWidth(0.1)
      doc.setDrawColor('#000000')
      doc.line(margin_x, margin_y, width - margin_x, margin_y)
      doc.line(margin_x, margin_y + 50, width - margin_x, margin_y + 50)
      doc.line(margin_x, margin_y, margin_x, margin_y + 50)
      doc.line(width - margin_x, margin_y, width - margin_x, margin_y + 50)
      // recuadro para la letra
      doc.line(width / 2, margin_y + 25, width / 2, margin_y + 50)
      doc.line((width / 2) - 12.5, margin_y + 25, (width / 2) + 12.5, margin_y + 25)
      doc.line((width / 2) - 12.5, margin_y, (width / 2) - 12.5, margin_y + 25)
      doc.line((width / 2) + 12.5, margin_y, (width / 2) + 12.5, margin_y + 25)

      // letra
      doc.setFontSize(48)
      doc.setFont('Poppins', 'bold')
      doc.text('X', width / 2, margin_y + 18, null, null, 'center')

      // info
      doc.setFontSize(12)
      doc.setFont('Poppins', 'medium')
      doc.text(factura.empresa_nombre, margin_x + 6, margin_y + 27)
      doc.setFontSize(10)
      doc.text(`Teléfono: ${factura.telefono}`, margin_x + 6, margin_y + 32)
      doc.text(`${factura.local_nombre}`, margin_x + 6, margin_y + 37)

      doc.setFontSize(20)
      doc.setFont('Poppins', 'bold')
      doc.text('Recibo', margin_x + 125, margin_y + 15)
      doc.setFontSize(12)
      doc.setFont('Poppins', 'medium')
      doc.text(`Nº ${factura.cobranza_emision}`, margin_x + 125, margin_y + 21)
      doc.setFontSize(10)
      doc.text(`Fecha: ${factura.fecha}`, margin_x + 125, margin_y + 27)
      const nombre = salto_linea(`Vendedor: ${factura.vendedor_codigo +' - '+ factura.vendedor_nombre}`, 40)
      doc.text(nombre.parte1, margin_x + 125, margin_y + 32)
      doc.text(nombre.parte2, margin_x + 144.5, margin_y + 37)

      factura = data.cliente;
      // datos del cliente
      doc.text(`Cliente: ${factura.vendedor_nombre}`, margin_x, margin_y + 57)
      doc.text(`Domicilio: ${factura.domicilio}`, margin_x, margin_y + 62)
      doc.text(`Documento: ${factura.tipo_documento+' - '+factura.numero_documento}`, margin_x, margin_y + 67)
      
      // encabezado del detalle
      doc.line(margin_x, margin_y + 70, width - margin_x, margin_y + 70)
      doc.setFontSize(11)
      doc.setFont('Poppins', 'bold')
      doc.text('Formas de Pago', margin_x + 50, margin_y + 85)
      doc.text('Importe', margin_x + 140, margin_y + 85)

      //y_final = margin_y + 78
    }
    setEncabezado()

    let y_final = 87; 

    for (const detalle of data.saldos_usados) {
      doc.setDrawColor('#757575')
      doc.line(margin_x, margin_y + y_final, width - margin_x, margin_y + y_final) //la primera linea gris luego del encabezado

      doc.setFontSize(10)
      doc.setFont('Poppins', 'regular')
      doc.text(`${detalle.forma_cobro_nombre}`, margin_x + 50, margin_y + y_final + 4)
      doc.text(`${format_money(detalle.importe)}`, margin_x + 140, margin_y + y_final + 4)

      y_final += 5.5;
    }

    // total en letras
    doc.setFontSize(12)
    doc.setFont('Poppins', 'medium')
    doc.text(`Son: ${NumerosALetras(data.encabezado.total_cobranza)}`, margin_x + 1, 248)

    // total numérico
    doc.setFontSize(18)
    doc.setFont('Poppins', 'bold')
    doc.text(`Total: ${format_money(data.encabezado.total_cobranza)}`, 135, 260)
    doc.setFontSize(12)
    doc.setFont('Poppins', 'medium')

    // devuelvo los bytes del pdf generado
    return doc.output('arraybuffer')  
  } catch (error) {
    return null;
  }
}

export async function remitoTermica (data) {
  const ancho = 80
  const altura = 450

  // instancio el pdf de tamaño large
  let doc = new jsPDF({ format: [ancho, altura] })

  // importo y seteo la feunte
  doc.addFont(require('./fuentes/Poppins-Bold.ttf'), 'Poppins', 'bold')
  doc.addFont(require('./fuentes/Poppins-Medium.ttf'), 'Poppins', 'medium')
  doc.setFont('Poppins', 'bold')
  doc.setFontSize(6)

  const remito = { ...data.venta }

  // titulos
  doc.text('* COMPROBANTE NO FISCAL *', 40, 7, null, null, 'center')
  doc.setFontSize(8)
  doc.text('REMITO', 40, 12, null, null, 'center')
  doc.text(`N° 0000-${data.id_remito.toString().padStart(8, '0')}`, 3, 19)
  doc.text(moment(remito.fecha).format('DD/MM/YYYY'), 72, 19, null, null, 'right')

  // nombres de los encabezados
  doc.setFontSize(7)
  doc.text('CUIT Nº:', 3, 24)
  doc.text('ING. BRUTOS:', 3, 28)
  doc.text('INICIO ACTIVIDADES:', 3, 32)
  doc.text('CAT. IVA:', 3, 36)

  doc.text('CLIENTE:', 3, 42)
  doc.text('CUIT/DNI:', 3, 46)
  doc.text('DOMICILIO:', 3, 50)
  doc.text('IVA:', 3, 54)

  doc.text('Codigo', 3, 60)
  doc.text('Descripcion', 40, 60, null, null, 'center')
  doc.text('Cant.', 72, 60, null, null, 'right')

  // lineas
  doc.setLineWidth(0.2)
  doc.line(3, 38, 72, 38)
  doc.setLineWidth(0.5)
  doc.line(3, 56, 72, 56)

  // datos de los enbabezdos
  doc.setFont('Poppins', 'medium')
  doc.text(remito.empresa_cuit, 14, 24)
  doc.text(remito.empresa_ingresos_brutos, 20, 28)
  doc.text(moment(remito.empresa_inicio_actividades).format('DD/MM/YYYY'), 30, 32)
  doc.text(remito.empresa_cond_iva, 16, 36)

  doc.text(remito.cliente_nombre, 14.5, 42)
  doc.text(remito.cliente_documento, 16, 46)
  doc.text(str(remito.cliente_domicilio, ''), 18, 50)
  doc.text(remito.cliente_cond_iva, 9, 54)

  /***   DETALLES   ***/

  let y_final = 60
  for (const detalle of data.detalles) {
    doc.setFontSize(7)
    doc.setFont('Poppins', 'medium')
    doc.text(detalle.articulo_codigo, 3, y_final + 4)
    doc.text(`${detalle.cantidad}`, 72, y_final + 4, null, null, 'right')

    y_final += 1
    const descripcion = split_varchar(detalle.descripcion, 45)
    for (const linea of descripcion) {
      doc.text(linea, 15, y_final + 3)
      y_final += 3
    }
  }

  y_final += 4

  doc.line(3, y_final, 72, y_final)

  doc.setFont('Poppins', 'bold')
  doc.text('FORMA PAGO:', 3, y_final + 4)
  doc.text('VENTA:', 3, y_final + 8)
  doc.text(`TOTAL: ${format_money(remito.total)}`, 72, y_final + 8, null, null, 'right')
  doc.text('RECIBI CONFORME:', 3, y_final + 14)

  doc.setFont('Poppins', 'medium')
  doc.text(data.formas_pago, 21, y_final + 4)
  doc.text(data.num_pedido, 13, y_final + 8)
  
  doc.setLineWidth(0.1)
  doc.text('Firma :', 14, y_final + 30)
  doc.line(25, y_final + 30, 66, y_final + 30)
  doc.text('N° Doc. :', 12, y_final + 40)
  doc.line(25, y_final + 40, 66, y_final + 40)
  doc.text('Aclaracion :', 7, y_final + 47)
  doc.line(25, y_final + 47, 66, y_final + 47)

  // devuelvo los bytes del pdf generado
  return doc.output('arraybuffer')
  
}

export async function remitoReciboA4 (data, tipo) {
  // defino las medidas de la hoja
  const width = 210   // ancho de 21 cm
  const height = 297  // alto de 29,7 cm
  const margin_y = 10 // margenes de 1 cm
  const margin_x = 5  // margenes de 0,5 cm

  const limite_inferior = height - margin_y
  const remito = { ...data.venta }
  const cliente = { ...data.cliente }
  const cobranza = { ...data.cobranza }
  let y_final = 0

  // creo la primer hoja del pdf (por defecto genera una a4)
  let doc = new jsPDF({ putOnlyUsedFonts: false })

  // importo y seteo la feunte
  doc.addFont(require('./fuentes/Poppins-Bold.ttf'), 'Poppins', 'bold')
  doc.addFont(require('./fuentes/Poppins-Medium.ttf'), 'Poppins', 'medium')
  doc.addFont(require('./fuentes/Poppins-Regular.ttf'), 'Poppins', 'regular')

  // obtengo la marca de agua
  const marcaAgua = ArrayBufferToBase64(await get_file('/empresas/aguaLITECH.png', 'image/png'))

  // funcion para setear el encabezado
  function setEncabezado () {
    // marca de wather
    doc.addImage(marcaAgua, "PNG", 0, 0, width, height)

    // recuadro rectangular
    doc.setLineWidth(0.1)
    doc.setDrawColor('#000000')
    doc.line(margin_x, margin_y, width - margin_x, margin_y)
    doc.line(margin_x, margin_y + 45, width - margin_x, margin_y + 45)
    doc.line(margin_x, margin_y, margin_x, margin_y + 45)
    doc.line(width - margin_x, margin_y, width - margin_x, margin_y + 45)
    // recuadro para la letra
    doc.line(width / 2, margin_y + 25, width / 2, margin_y + 45)
    doc.line((width / 2) - 12.5, margin_y + 25, (width / 2) + 12.5, margin_y + 25)
    doc.line((width / 2) - 12.5, margin_y, (width / 2) - 12.5, margin_y + 25)
    doc.line((width / 2) + 12.5, margin_y, (width / 2) + 12.5, margin_y + 25)

    // letra
    doc.setFontSize(48)
    doc.setFont('Poppins', 'bold')
    doc.text('X', width / 2, margin_y + 18, null, null, 'center')

    // info
    doc.setFontSize(12)
    doc.setFont('Poppins', 'medium')
    doc.text(remito.empresa_nombre, margin_x + 3, margin_y + 27)
    doc.setFontSize(10)
    doc.text(`Teléfono: ${remito.empresa_telefono}`, margin_x + 3, margin_y + 32)
    doc.text(`${remito.local_nombre}`, margin_x + 3, margin_y + 37)
    doc.text(`${remito.sucursal_nombre}`, margin_x + 3, margin_y + 42)

    doc.setFontSize(20)
    doc.setFont('Poppins', 'bold')
    doc.text(tipo, margin_x + 130, margin_y + 15)
    doc.setFontSize(12)
    doc.setFont('Poppins', 'medium')
    doc.text(`Nº ${tipo == 'Remito' ? remito.venta_id : cobranza.cobranza_emision}`, margin_x + 130, margin_y + 21)
    doc.setFontSize(10)
    doc.text(`Fecha: ${remito.fecha}`, margin_x + 130, margin_y + 27)
    const vendedor = salto_linea(`Vendedor: ${remito.vendedor_codigo} - ${remito.vendedor_nombre}`, 30)
    doc.text(vendedor.parte1, margin_x + 130, margin_y + 32)
    doc.text(vendedor.parte2, margin_x + 130, margin_y + 37)

    // datos del cliente
    doc.text(`Cliente: ${cliente.nombre}`, margin_x, margin_y + 52)
    doc.text(`Domicilio: ${cliente.domicilio}`, margin_x, margin_y + 57)
    doc.text(`Documento: ${cliente.tipodoc} - ${cliente.numdoc}`, margin_x, margin_y + 62)
    
    // encabezado del detalle
    if (tipo == 'Remito') {
      doc.line(margin_x, margin_y + 65, width - margin_x, margin_y + 65)
      doc.setFont('Poppins', 'bold')
      doc.text('Cant.', margin_x + 1, margin_y + 70)
      doc.text('Código', margin_x + 20, margin_y + 70)
      doc.text('Descripción', margin_x + 40, margin_y + 70)
      doc.text('Precio Unit.', margin_x + 150, margin_y + 70)
      doc.text('Importe', width - margin_x - 1, margin_y + 70, null, null, 'right')
      y_final = margin_y + 73
    } else {
      doc.line(margin_x, margin_y + 65, width - margin_x, margin_y + 65)
      doc.setFont('Poppins', 'bold')
      doc.text('Formas de Pago', margin_x + 50, margin_y + 85)
      doc.text('Importe', margin_x + 120, margin_y + 85)
      y_final = margin_y + 88
    }
  }
  setEncabezado()

  if (tipo == 'Remito') {
    function saltoPagina (alto) {
      if (y_final + alto > limite_inferior) {
        doc.addPage()
        setEncabezado()
        y_final = margin_y + 73
      }
    }

    for (const detalle of data.detalles) {
      // controlo si hace falta un salto de pagina
      saltoPagina(15)
  
      doc.setDrawColor('#757575')
      doc.line(margin_x, y_final, width - margin_x, y_final)
  
      doc.setFontSize(8)
      doc.setFont('Poppins', 'regular')
      doc.text(`${detalle.cantidad}`, margin_x + 5, y_final + 4.5)
      doc.text(`${detalle.articulo_codigo ? detalle.articulo_codigo : ''}`, margin_x + 20, y_final + 4.5)
  
      doc.text(`$ ${detalle.itempreciounitario.toFixed(2).replace('.', ',')}`, width - margin_x - 32, y_final + 4.5, null, null, 'right')
      doc.text(`$ ${detalle.importeitem.toFixed(2).replace('.', ',')}`, width - margin_x - 1, y_final + 4.5, null, null, 'right')
  
      const descripcion = split_varchar(detalle.descripcion, 70)
      for (const linea of descripcion) {
        saltoPagina(5)
        doc.text(linea, margin_x + 40, y_final + 4)
        y_final += 4
      }
  
      y_final += 2.5
    }

    // verifico si el footer entra en lo que quedo de pagina
    saltoPagina(55)
  } else {
    for (const detalle of cobranza.detalle) {
      doc.setDrawColor('#757575')
      doc.line(margin_x, y_final, width - margin_x, y_final)
  
      doc.setFontSize(11)
      doc.setFont('Poppins', 'regular')
      doc.text(`${detalle.nombre}`, margin_x + 50, y_final + 4.5)
      doc.text(`${format_money(detalle.total)}`, margin_x + 120, y_final + 4.5)
  
      y_final += 6.5
    }
  }

  // total
  doc.setFontSize(12)
  doc.setFont('Poppins', 'medium')

  const total = tipo == 'Remito' ? remito.total : cobranza.total
  const numero = salto_linea(`Son: ${NumerosALetras(total)}`, 80)
  doc.text(numero.parte1, margin_x + 1, 250)
  doc.text(numero.parte2, margin_x + 1, 255)
  doc.setFontSize(18)
  doc.setFont('Poppins', 'bold')
  doc.text(`Total: ${format_money(total)}`, 135, 270)

  // solo si tiene + de 1 hoja agrego la numeracion
  if (doc.getNumberOfPages() > 1) {
    doc.setFontSize(10)
    for (let index = 1; index <= doc.getNumberOfPages(); index++) {
      doc.setPage(index)
      doc.text(`Hoja ${index} de ${doc.getNumberOfPages()}`, margin_x + 130, margin_y + 40)
    }
  }

  // devuelvo los bytes del pdf generado
  return doc.output('arraybuffer')
}

export {
  giftcardA4,
  giftcardA4_masiva,
  reciboEfDycarA4,
  reporteControlStock,
  planillaArtStock,
  reporteParaguay,
  reporteRemitoInterno,
  libroIvaPdf,
  pdfCobroOnline,
  etiquetasPrecio,
  barcodePDF,
  operacionesCajaTermica,
  operacionesCajaA4,
  operacionesAceptacionA4,
  facturaTermica,
  facturaA4,
  reciboLitechA4
}